import { useContext, Fragment, Suspense, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { UserIdContext } from "../routes/Root";
import { auth } from "../core/firebase";

/**
 * The minimal app layout to be used on pages such Login/Signup,
 * Privacy Policy, Terms of Use, etc.
 */
export function BaseLayout(): JSX.Element {
  const userId = useContext(UserIdContext);

  useEffect(() => {
    console.log("checking auth status");
    if (userId) {
      // auth.signOut();
      console.log("signed in");
    }
  });

  return (
    <Fragment>
      <Suspense>
        <Outlet />
      </Suspense>
    </Fragment>
  );
}
