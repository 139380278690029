import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import { RouterProvider } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { router } from "./routes";
import { theme } from "./theme";

function App() {
  console.log("in app render");
  return (
    <RecoilRoot>
      <ColorModeScript />
      <ChakraProvider theme={theme}>
        <RouterProvider router={router} />
      </ChakraProvider>
    </RecoilRoot>
  );
}

export default App;
