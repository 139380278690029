import {
  Alert,
  AlertDescription,
  AlertIcon,
  Avatar,
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  InputRightAddon,
  Stack,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import { type DocumentReference } from "firebase/firestore";
import moment from "moment";
import { useForm } from "react-hook-form";
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from "recoil";
import { saveAgentProfile } from "../../services/ApiService";
import {
  userIdState,
  agentProfileState,
  userProfileState,
} from "../../state/User";
import { IAgentProfile } from "../../types/IAgentProfile";

import { useState } from "react";
import { storage } from "../../core/firebase";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import useFileUpload from "../../hooks/useFileUpload";
import { ImageUpload } from "../../hooks/useUpload";
import { useNavigate } from "react-router-dom";
import { FilePondFile, FilePondInitialFile } from "filepond";
import { DeleteIcon } from "@chakra-ui/icons";
import { PageHeader } from "../../components/PageHeader";
import InputMask from "react-input-mask";
import { validateEmail } from "../../core/validation";

export const AgentProfile = () => {
  const navigate = useNavigate();
  const userId = useRecoilValue(userIdState);
  const agentProfile = useRecoilValue(agentProfileState);
  const userProfile = useRecoilValue(userProfileState);
  const refreshAgentProfile = useRecoilRefresher_UNSTABLE(agentProfileState);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm();

  const [photoUrl, setPhotoUrl] = useState<string | null>(null);
  const [logoUrl, setLogoUrl] = useState<string | null>(null);

  const onSubmit = async (data: any) => {
    try {
      if (photoUrl === null && !agentProfile?.photoUrl) {
        throw Error("A photo is required");
      }

      if (logoUrl === null && !agentProfile?.companyLogoUrl) {
        throw Error("A company logo is required");
      }

      const agentProfileUpdates: IAgentProfile = {
        fullName: data.fullName.trim(),
        email: data.email.trim(),
        phone: data.phone.trim(),
        altPhone: data.altPhone?.trim(),
        website: data.website.trim(),
        companyName: data.companyName.trim(),
        companyLogoUrl: logoUrl || agentProfile?.companyLogoUrl || "",
        photoUrl: photoUrl || agentProfile?.photoUrl || "",
      };

      await saveAgentProfile(data.userId, agentProfileUpdates);
      refreshAgentProfile();
      navigate("/dashboard");
    } catch (error: any) {
      if (error != null && typeof error === "object") {
        setError("root.submissionError", {
          message:
            error.message ||
            "An unknown error occurred. We logged it and are looking into it.",
        });
      } else {
        setError("root.submissionError", {
          message:
            "An unknown error occurred. We logged it and are looking into it.",
        });
      }
    }
  };

  return (
    <Container>
      <Stack spacing={{ base: "8", lg: "6" }}>
        <PageHeader
          title="Edit Your Client-Facing Profile"
          subtitle="Your clients will see this in the EasyHome app."
        />

        {errors.root?.submissionError && (
          <Alert status="error">
            <AlertIcon />
            <AlertDescription>
              {errors.root?.submissionError.message}
            </AlertDescription>
          </Alert>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing="5">
            <Stack spacing="3" divider={<StackDivider />}>
              <FormControl id="photo">
                <Stack
                  direction={{ base: "column", md: "row" }}
                  spacing={{ base: "1.5", md: "8" }}
                  justify="space-between"
                >
                  <FormLabel variant="inline">
                    Photo*
                    <br />
                    <em>
                      Ideally square and
                      <br />
                      at least 500px x 500px
                    </em>
                  </FormLabel>
                  <Stack
                    spacing={{ base: "3", md: "5" }}
                    direction={{ base: "row", sm: "row" }}
                    width="full"
                    maxW={{ md: "3xl" }}
                  >
                    <ImageUpload
                      onSave={(file, remoteUrl) => {
                        setPhotoUrl(remoteUrl);
                      }}
                      initialFiles={
                        agentProfile?.photoUrl
                          ? [
                              {
                                source: agentProfile?.photoUrl,
                                options: {
                                  type: "local",
                                },
                              } as FilePondInitialFile,
                            ]
                          : []
                      }
                      allowMultiple={false}
                      imagesOnly={true}
                      serverPath={`agents/${userId}/profile`}
                    />
                  </Stack>
                </Stack>
              </FormControl>

              <FormControl id="fullName">
                <Stack
                  direction={{ base: "column", md: "row" }}
                  spacing={{ base: "1.5", md: "8" }}
                  justify="space-between"
                >
                  <FormLabel variant="inline">Full Name*</FormLabel>
                  <VStack align="flex-start" spacing="1" w="3xl">
                    <Input
                      maxW={{ md: "3xl" }}
                      isInvalid={errors?.fullName ? true : false}
                      defaultValue={
                        agentProfile?.fullName ||
                        `${userProfile?.firstName} ${userProfile?.lastName}`
                      }
                      {...register("fullName", { required: true })}
                    />
                    {errors.fullName && (
                      <Text pt={1} color="error" fontSize="sm" role="alert">
                        This field is required
                      </Text>
                    )}
                  </VStack>
                </Stack>
              </FormControl>
              <FormControl id="email">
                <Stack
                  direction={{ base: "column", md: "row" }}
                  spacing={{ base: "1.5", md: "8" }}
                  justify="space-between"
                >
                  <FormLabel variant="inline">Email*</FormLabel>
                  <VStack align="flex-start" spacing="1" w="3xl">
                    <Input
                      type="email"
                      maxW={{ md: "3xl" }}
                      isInvalid={errors?.email ? true : false}
                      defaultValue={agentProfile?.email || userProfile?.email}
                      {...register("email", {
                        validate: validateEmail,
                      })}
                    />
                    {errors.email && (
                      <Text pt={1} color="error" fontSize="sm" role="alert">
                        Enter a valid email address
                      </Text>
                    )}
                  </VStack>
                </Stack>
              </FormControl>

              <FormControl id="phone">
                <Stack
                  direction={{ base: "column", md: "row" }}
                  spacing={{ base: "1.5", md: "8" }}
                  justify="space-between"
                >
                  <FormLabel variant="inline">Phone*</FormLabel>
                  <VStack align="flex-start" spacing="1" w="3xl">
                    <InputGroup maxW={{ md: "3xl" }}>
                      <InputLeftElement>
                        <Text>+1</Text>
                      </InputLeftElement>
                      <Input
                        as={InputMask}
                        isInvalid={errors?.phone ? true : false}
                        defaultValue={agentProfile?.phone || userProfile?.phone}
                        mask="(999) 999-9999"
                        maskChar={"_"}
                        {...register("phone", { required: true })}
                      />
                    </InputGroup>
                    {errors.phone && (
                      <Text pt={1} color="error" fontSize="sm" role="alert">
                        This field is required
                      </Text>
                    )}
                  </VStack>
                </Stack>
              </FormControl>
              <FormControl id="altPhone">
                <Stack
                  direction={{ base: "column", md: "row" }}
                  spacing={{ base: "1.5", md: "8" }}
                  justify="space-between"
                >
                  <FormLabel variant="inline">Alt Phone (optional)</FormLabel>
                  <InputGroup maxW={{ md: "3xl" }}>
                    <InputLeftElement>
                      <Text>+1</Text>
                    </InputLeftElement>
                    <Input
                      as={InputMask}
                      defaultValue={agentProfile?.altPhone}
                      mask="(999) 999-9999"
                      maskChar={"_"}
                      {...register("altPhone")}
                    />
                  </InputGroup>
                </Stack>
              </FormControl>

              <FormControl id="website">
                <Stack
                  direction={{ base: "column", md: "row" }}
                  spacing={{ base: "1.5", md: "8" }}
                  justify="space-between"
                >
                  <FormLabel variant="inline">Website (optional)</FormLabel>
                  <InputGroup maxW={{ md: "3xl" }}>
                    <Input
                      defaultValue={agentProfile?.website}
                      placeholder="https://"
                      {...register("website")}
                    />
                  </InputGroup>
                </Stack>
              </FormControl>
              <FormControl id="companyName">
                <Stack
                  direction={{ base: "column", md: "row" }}
                  spacing={{ base: "1.5", md: "8" }}
                  justify="space-between"
                >
                  <FormLabel variant="inline">Company Name*</FormLabel>
                  <VStack align="flex-start" spacing="1" w="3xl">
                    <Input
                      maxW={{ md: "3xl" }}
                      isInvalid={errors?.companyName ? true : false}
                      defaultValue={
                        agentProfile?.companyName || userProfile?.companyName
                      }
                      {...register("companyName", { required: true })}
                    />
                    {errors.companyName && (
                      <Text pt={1} color="error" fontSize="sm" role="alert">
                        This field is required
                      </Text>
                    )}
                  </VStack>
                </Stack>
              </FormControl>
              <FormControl id="companyLogo">
                <Stack
                  direction={{ base: "column", md: "row" }}
                  spacing={{ base: "1.5", md: "8" }}
                  justify="space-between"
                >
                  <FormLabel variant="inline">
                    Company Logo*
                    <br />
                    <em>
                      Ideally square and
                      <br />
                      at least 500px x 500px
                    </em>
                  </FormLabel>
                  <Stack
                    spacing={{ base: "3", md: "5" }}
                    direction={{ base: "column", sm: "row" }}
                    width="full"
                    maxW={{ md: "3xl" }}
                  >
                    <ImageUpload
                      onSave={(file, remoteUrl) => {
                        setLogoUrl(remoteUrl);
                      }}
                      initialFiles={
                        agentProfile?.companyLogoUrl
                          ? [
                              {
                                source: agentProfile?.companyLogoUrl,
                                options: {
                                  type: "local",
                                },
                              } as FilePondInitialFile,
                            ]
                          : []
                      }
                      allowMultiple={false}
                      imagesOnly={true}
                      serverPath={`agents/${userId}/logo`}
                    />
                    {/* <Image
                      src={
                        logoDataResponse?.downloadUrl ||
                        agentProfile?.companyLogoUrl
                      }
                      alt="Agent Profile"
                      boxSize="150px"
                      objectFit="cover"
                      fallbackSrc="https://via.placeholder.com/150"
                    />
                    <input
                      type="file"
                      onChange={(e: any) => {
                        setLogoFileData(e.target.files[0]);
                      }}
                    />
                    {logoIsLoading && (
                      <Box color="teal.100">
                        <Box
                          color="teal"
                          width={`${logoProgress?.value || 0}%`}
                        >
                          {logoProgress?.value || 0}%
                        </Box>
                      </Box>
                    )} */}
                  </Stack>
                </Stack>
              </FormControl>

              <Input
                type="hidden"
                value={userId || ""}
                {...register("userId", { required: true })}
              />

              <Flex direction="row-reverse">
                <Stack spacing="4">
                  <Button
                    isLoading={isSubmitting}
                    variant="primary"
                    type="submit"
                  >
                    Save Changes
                  </Button>
                </Stack>
              </Flex>
            </Stack>
          </Stack>
        </form>
      </Stack>
    </Container>
  );
};
