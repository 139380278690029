import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  StackProps,
  Text,
  Link as ChakraLink,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { PasswordField } from '../../components/PasswordField';
import { auth, functions } from '../../core/firebase';
import { httpsCallable, HttpsCallableResult } from 'firebase/functions';
import {
  useSendPasswordResetEmail,
  useSignInWithEmailAndPassword,
} from 'react-firebase-hooks/auth';
import { UserCredential, sendPasswordResetEmail } from 'firebase/auth';
import { useState } from 'react';

interface IConfirmAccountSignInProps extends StackProps {
  maskedEmail: string;
}

export const ConfirmAccountSignInForm = (props: IConfirmAccountSignInProps) => {
  const { maskedEmail } = props;
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm();

  const {
    register: registerReset,
    handleSubmit: handleSubmitReset,
    setError: setErrorReset,
    formState: { errors: errorsReset, isSubmitting: isSubmittingReset },
  } = useForm();

  const navigate = useNavigate();
  let { id } = useParams();
  const [signIn] = useSignInWithEmailAndPassword(auth);

  const [sendPasswordResetEmail] = useSendPasswordResetEmail(auth);
  const [isResetPasswordFormOpen, setIsResetPasswordFormOpen] = useState(false);
  const [isResetSubmitted, setIsResetSubmitted] = useState(false);
  const onCloseResetPasswordForm = () => setIsResetPasswordFormOpen(false);

  const onSendResetSubmit = async (data: any) => {
    await sendPasswordResetEmail(data.resetEmail);
    setIsResetSubmitted(true);
  };

  const onSubmit = async (data: any) => {
    try {
      const signInResult: UserCredential | undefined = await signIn(
        data.email.toLowerCase().trim(),
        data.password.trim()
      );

      if (signInResult?.user.uid) {
        const linkAgentToRequest = httpsCallable(
          functions,
          'clientConnect-linkAgentToRequest'
        );
        const linkAgentToRequestResult: HttpsCallableResult =
          await linkAgentToRequest({
            accessRequestId: id,
            userId: signInResult?.user.uid,
            email: data.email.toLowerCase().trim(),
          });

        if (linkAgentToRequestResult.data === true) {
          navigate('/dashboard');
          return;
        }
      } else {
        setError('root.submissionError', {
          message:
            "We couldn't verify the info you submitted. Please try again.",
        });
      }
    } catch (error: any) {
      if (error != null && typeof error === 'object') {
        setError('root.submissionError', {
          message: error.message || 'An error occurred.',
        });
      } else {
        setError('root.submissionError', { message: 'An error occurred.' });
      }
    }
  };

  return (
    <Box
      py={{ base: '0', sm: '8' }}
      px={{ base: '4', sm: '10' }}
      bg={{ base: 'transparent', sm: 'bg-surface' }}
      boxShadow={{ base: 'none', sm: 'md' }}
      borderRadius={{ base: 'none', sm: 'xl' }}
    >
      <Modal
        isOpen={isResetPasswordFormOpen}
        onClose={onCloseResetPasswordForm}
      >
        <ModalOverlay />
        <ModalContent mx={6}>
          <ModalHeader>Send Password Reset Link</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Text pb={3}>
              Enter your email address below and we'll send you a link to reset
              your password.
            </Text>
            {isResetSubmitted && (
              <Text color={'success'}>
                A password reset link is on the way. Please check your email.
              </Text>
            )}

            {!isResetSubmitted && (
              <form onSubmit={handleSubmitReset(onSendResetSubmit)}>
                <Stack spacing='5'>
                  <FormControl id='resetEmail'>
                    <FormLabel>Enter your email</FormLabel>
                    <Input
                      type='email'
                      placeholder='Enter email'
                      isInvalid={errorsReset?.resetEmail ? true : false}
                      {...registerReset('resetEmail', {
                        required: 'Email is required',
                      })}
                    />
                    {errorsReset.resetEmail && (
                      <Text pt={1} color='error' fontSize='sm' role='alert'>
                        This field is required
                      </Text>
                    )}
                  </FormControl>
                  <Button
                    variant='primary'
                    isLoading={isSubmittingReset}
                    type='submit'
                  >
                    Send Reset Link
                  </Button>
                </Stack>
              </form>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
      <Stack spacing='6'>
        {errors.root && (
          <Alert status='error'>
            <AlertIcon />
            <AlertDescription>
              {errors.root.submissionError.message}
            </AlertDescription>
          </Alert>
        )}
        <Text fontWeight='bold' color='muted'>
          We found an Easyhome account on file for you. Sign in to link it to
          your agent profile.
        </Text>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing='5'>
            <FormControl id='email'>
              <FormLabel>Confirm your email "{maskedEmail}"</FormLabel>
              <Input
                type='email'
                placeholder='confirm email'
                isInvalid={errors?.email ? true : false}
                {...register('email', {
                  required: 'Email is required',
                })}
              />
              {errors.email && (
                <Text pt={1} color='error' fontSize='sm' role='alert'>
                  This field is required
                </Text>
              )}
            </FormControl>
            <PasswordField
              {...register('password')}
              showHint={false}
              title='Enter your EasyHome app password'
            />
            <Button
              variant='link'
              colorScheme='teal'
              size='sm'
              onClick={() => setIsResetPasswordFormOpen(true)}
            >
              Forgot password? Send a reset link.
            </Button>
            <Button variant='primary' isLoading={isSubmitting} type='submit'>
              Submit
            </Button>
            <Text fontSize='xs' lineHeight={1.3}>
              By clicking "Submit" you agree to EasyHome's{' '}
              <ChakraLink
                as={Link}
                to='https://fixlehome.com/fixle-terms-of-use'
                color='teal.500'
                target='_blank'
              >
                Terms of Use
              </ChakraLink>{' '}
              and{' '}
              <ChakraLink
                color='teal.500'
                as={Link}
                to='https://fixlehome.com/fixle-privacy-notice'
                target='_blank'
              >
                Privacy Policy
              </ChakraLink>
              .
            </Text>
          </Stack>
        </form>
      </Stack>
    </Box>
  );
};
