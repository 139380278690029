import {
  Box,
  Center,
  DarkMode,
  Flex,
  Heading,
  Link,
  Stack,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import { Logo } from './components/Logo';

type AuthPageLayoutProps = {
  children: any;
};

export const AuthPageLayout: React.FC<AuthPageLayoutProps> = ({ children }) => (
  <Flex
    minH={{ base: 'auto', md: '100vh' }}
    bgGradient={{
      md: mode(
        'linear(to-r, teal.600 50%, white 50%)',
        'linear(to-r, teal.600 50%, gray.900 50%)'
      ),
    }}
  >
    <Flex maxW='8xl' mx='auto' width='full'>
      <Box flex='1' display={{ base: 'none', md: 'block' }}>
        <DarkMode>
          <Flex
            direction='column'
            px={{ base: '4', md: '8' }}
            height='full'
            color='on-accent'
          >
            <Flex align='center' h='24'>
              <Logo color='white' />
            </Flex>
            <Flex flex='1' align='center'>
              <Stack spacing='8'>
                <Stack spacing='6'>
                  <Heading size={{ md: 'lg', xl: 'lg' }}>
                    Keep Clients for Life With EasyHome Client Connect.
                  </Heading>
                  <Text fontSize='lg' maxW='md' fontWeight='medium'>
                    Help clients care for their homes while staying top of mind
                    - a real win-win.
                  </Text>
                  <Link
                    href='https://www.easyhomeinc.com/real-estate-pro'
                    target='_blank'
                    rel='noopener noreferrer'
                    fontWeight='semibold'
                    color='teal.100'
                  >
                    Learn more ➔
                  </Link>
                </Stack>
              </Stack>
            </Flex>
            <Flex align='center' h='24'>
              <Text color='on-accent-subtle' fontSize='sm'>
                © 2024 EasyHome, Inc. All rights reserved.
              </Text>
            </Flex>
          </Flex>
        </DarkMode>
      </Box>
      <Center flex='1'>{children}</Center>
    </Flex>
  </Flex>
);
