import { Fragment, ReactNode, useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserIdContext } from "./Root";
import { useRecoilValueLoadable } from "recoil";
import { userProfileState } from "../state/User";
import { Flex, Spinner } from "@chakra-ui/react";
import { UserProfileSectionLoading } from "../layout/components/UserProfileSection";

interface PrivateRouteProps {
  children: ReactNode;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const userId = useContext(UserIdContext);
  const userProfile = useRecoilValueLoadable(userProfileState);

  if (userProfile.state === "loading") {
    return (
      <Flex height="100vh" bg="bg-canvas" justify="center" align="center">
        <Spinner size="xl" />
      </Flex>
    );
  }

  if (userId) {
    const profile = userProfile.valueMaybe();
    if (typeof profile !== "undefined" && !profile.isAgent) {
      // show a "no access" page
      return <Navigate to="/no-access" />;
    } else if (typeof profile !== "undefined" && profile.isAgent) {
      return <Fragment>{children}</Fragment>;
    }
    return <Fragment />;
  } else {
    return <Navigate to="/auth/sign-in" />;
  }
};
