import {
  getStripePayments,
  getProducts,
  createCheckoutSession,
} from "@stripe/firestore-stripe-payments";
import { useCallback } from "react";
import { config } from "./config";
import { app } from "./firebase";

export const payments = getStripePayments(app, {
  productsCollection: "products",
  customersCollection: "users",
});

export const useGetProducts = () => {
  return useCallback(
    () =>
      getProducts(payments, {
        includePrices: true,
        activeOnly: true,
        where: [["name", "==", "ClientConnect Subscription"]],
      }),
    []
  );
};

export const useCreateCheckoutSession = () => {
  return useCallback(
    (priceId: string) =>
      createCheckoutSession(payments, {
        price: priceId,
        mode: "subscription",
        allow_promotion_codes: true,
      }),
    []
  );
};

export const useCreateCheckoutSessionForGift = () => {
  return useCallback(
    (clientInviteId: string) =>
      createCheckoutSession(payments, {
        line_items: [
          {
            price: config.stripe.giftPriceId,
            quantity: 1,
          },
        ],
        // TODO:  These aren't working with the provided firebase extension...
        //        follow-up on it later to see if support has been added, or to
        //        fork the extension and call it ourselves
        //
        // custom_fields: [
        //   {
        //     key: "noteToClient",
        //     label: {
        //       type: "custom",
        //       custom: "Write a short note to your client",
        //     },
        //     type: "text",
        //     optional: true,
        //   },
        // ],
        success_url: config.app.origin + "/client-gifts/success",
        metadata: {
          clientInviteId,
        },
        mode: "payment",
      }),
    []
  );
};
