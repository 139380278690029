import { lazy, Suspense } from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import { AppLayout } from "../layout/AppLayout";
import { BaseLayout } from "../layout/BaseLayout";
import { RootError } from "../layout/RootError";
import { AddClient } from "./AddClient/AddClient";
import { AgentProfile } from "./AgentProfile/AgentProfile";
import { Clients } from "./Clients/Clients";
import ConfirmAccount from "./ConfirmAccount/ConfirmAccount";
import { PrivateRoute } from "./PrivateRoute";
import ResetPassword from "./ResetPassword/ResetPassword";
import { Root } from "./Root";
import { EditClient } from "./EditClient/EditClient";
import Terms from "./Legal/Terms";
import Privacy from "./Legal/Privacy";
import { Settings } from "./Settings/Settings";
import NoAccess from "./NoAccess/NoAccess";
import { ClientGifts } from "./ClientGifts/ClientGifts";
import { DocumentUploads } from "./DocumentUploads/DocumentUploads";
import { DocumentUploadSuccess } from "./DocumentUploads/DocumentUploadSuccess";
import { ClientGiftSuccess } from "./ClientGifts/ClientGiftSuccess";

const RequestAccess = lazy(() => import("./RequestAccess/RequestAccess"));
const RequestAccessSuccess = lazy(
  () => import("./RequestAccess/Success/RequestAccessSuccess")
);
const Dashboard = lazy(() => import("./Dashboard/Dashboard"));
const SignIn = lazy(() => import("./SignIn/SignIn"));

export const router = createBrowserRouter([
  {
    path: "",
    element: <Root />,
    errorElement: <RootError />,
    children: [
      {
        path: "auth",
        element: <BaseLayout />,
        children: [
          { path: "sign-in", element: <SignIn /> },
          { path: "reset-password", element: <ResetPassword /> },
          { path: "confirm-account/:id", element: <ConfirmAccount /> },
          { path: "request-access", element: <RequestAccess /> },
          { path: "request-access/success", element: <RequestAccessSuccess /> },
        ],
      },
      { path: "terms", element: <Terms /> },
      { path: "privacy", element: <Privacy /> },
      { path: "no-access", element: <NoAccess /> },
      {
        path: "",
        element: (
          <PrivateRoute>
            <AppLayout />
          </PrivateRoute>
        ),
        errorElement: <RootError />,
        children: [
          { index: true, element: <Navigate to="/dashboard" replace /> },
          { path: "dashboard", element: <Dashboard /> },
          { path: "clients", element: <Clients /> },
          { path: "clients/add", element: <AddClient /> },
          {
            path: "clients/docs/:clientInviteId",
            element: <DocumentUploads />,
          },
          {
            path: "clients/docs/:clientInviteId/success",
            element: <DocumentUploadSuccess />,
          },
          { path: "clients/edit/:clientInviteId", element: <EditClient /> },
          { path: "client-gifts", element: <ClientGifts /> },
          { path: "client-gifts/success", element: <ClientGiftSuccess /> },
          { path: "profile", element: <AgentProfile /> },
          { path: "settings", element: <Settings /> },
        ],
      },
    ],
  },
]);
