import { Flex, Spinner, Container } from "@chakra-ui/react";
import { PageHeader } from "../../components/PageHeader";
import { useState, useEffect } from "react";
import { IClientInvite } from "../../types/IClientInvite";
import { EditClientForm } from "./EditClientForm";
import { useRecoilValue } from "recoil";
import { userIdState } from "../../state/User";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../core/firebase";

export const EditClient = () => {
  let { clientInviteId } = useParams();
  const userId = useRecoilValue(userIdState);
  const [client, setClient] = useState<IClientInvite | null>(null);

  useEffect(() => {
    const fetchClient = async (clientId: string, userId: string) => {
      const docRef = doc(db, "agents", userId, "client-invites", clientId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        setClient(docSnap.data());
      } else {
        console.log("No client found");
      }
    };

    if (typeof clientInviteId !== "undefined" && userId !== null) {
      fetchClient(clientInviteId, userId);
    }
  }, [userId, clientInviteId]);

  if (client === null) {
    return (
      <Flex height="100vh" bg="bg-canvas" justify="center" align="center">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <Container>
      <PageHeader
        title="Edit A Client"
        subtitle="Fill out all the required fields and submit to send your client a
          personalized invitation to the EasyHome app."
      />
      <EditClientForm clientInvite={client} />
    </Container>
  );
};
