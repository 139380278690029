import { createContext } from "react";
import { Outlet } from "react-router-dom";
import { useRecoilValueLoadable } from "recoil";
import { userIdState } from "../state/User";
import { Flex, Spinner } from "@chakra-ui/react";

export const UserIdContext = createContext<string | null>(null);

export const Root = () => {
  const userId = useRecoilValueLoadable(userIdState);

  if (userId.state === "loading") {
    return (
      <Flex height="100vh" bg="bg-canvas" justify="center" align="center">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <UserIdContext.Provider value={userId.valueOrThrow()}>
      <Outlet />
    </UserIdContext.Provider>
  );
};
