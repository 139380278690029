import { AuthPageLayout } from "../../layout/AuthPageLayout";
import { NoAccessForm } from "./NoAccessForm";

const NoAccess = () => (
  <AuthPageLayout>
    <NoAccessForm
      px={{ base: "4", md: "8" }}
      py={{ base: "12", md: "48" }}
      width="full"
      maxW="md"
    />
  </AuthPageLayout>
);

export default NoAccess;
