import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import InputMask from "react-input-mask";
import { Controller, useForm } from "react-hook-form";
import { toErrorWithMessage } from "../../core/errors";
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from "recoil";
import { createClientInvite } from "../../services/ApiService";
import { clientCountState, userIdState } from "../../state/User";
import Autocomplete from "react-google-autocomplete";
import { IAddress } from "../../types/IAddress";
import { useState } from "react";
import { config } from "../../core/config";
import { useNavigate, Link as ReactRouterLink } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../core/firebase";
import { validateEmail } from "../../core/validation";

export const AddClientForm = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const [place, setPlace] = useState<any>(null);
  const userId = useRecoilValue(userIdState);
  const refreshClientCount = useRecoilRefresher_UNSTABLE(clientCountState);
  const {
    register,
    handleSubmit,
    setError,
    control,
    formState: { errors, isSubmitting },
  } = useForm();

  const onSubmit = async (data: any) => {
    if (!place) {
      setError("address", {
        message: "Please enter an address",
      });
      return;
    }

    // build the address from the selected place
    const address: IAddress = {
      googlePlaceId: place.place_id,
      streetNumber: place.address_components?.find(
        (c: any) => c.types[0] === "street_number"
      )?.long_name,
      streetShort: place.address_components?.find(
        (c: any) => c.types[0] === "route"
      )?.short_name,
      streetLong: place.address_components?.find(
        (c: any) => c.types[0] === "route"
      )?.long_name,
      city: place.address_components?.find(
        (c: any) => c.types[0] === "locality"
      )?.long_name,
      state: place.address_components?.find(
        (c: any) => c.types[0] === "administrative_area_level_1"
      )?.short_name,
      postalCode: place.address_components?.find(
        (c: any) => c.types[0] === "postal_code"
      )?.long_name,
      latitude: place.geometry?.location.lat(),
      longitute: place.geometry?.location.lng(),
      unit: data.unit,
    };

    const clientInvite = {
      agentId: data.agentId,
      firstName: data.firstName.trim(),
      lastName: data.lastName.trim(),
      email: data.email.toLowerCase().trim(),
      phone: data.phone.trim(),
      address: address,
      customInviteContent: data.customInviteContent.trim(),
      agentNotes: data.agentNotes.trim(),
    };

    try {
      if (
        typeof address.streetNumber === "undefined" ||
        address.streetNumber === null
      ) {
        throw new Error(
          "The seleted address is missing a street number. Please try a different address."
        );
      }

      if (address.state !== "OR") {
        throw new Error(
          "EasyHome is only serving homes in Oregon at this time. Please enter a different address."
        );
      }

      const clientInviteResult = await createClientInvite(clientInvite);
      if (clientInviteResult === false) {
        throw new Error("Failed to create client invite");
      }
      const sendClientInviteEmail = httpsCallable(
        functions,
        "clientConnect-sendClientInviteEmail"
      );
      await sendClientInviteEmail({
        clientInviteId: clientInviteResult.id,
      });
      await refreshClientCount();
      onOpen();
    } catch (error) {
      // console.log(error);
      const errorWithMessage = toErrorWithMessage(error);
      setError("root.submissionError", { message: errorWithMessage.message });
    }
  };

  return (
    <Stack spacing={{ base: "6", lg: "6" }}>
      {errors.root?.submissionError && (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>
            {errors.root?.submissionError.message}
          </AlertDescription>
        </Alert>
      )}

      <Modal
        isOpen={isOpen}
        onClose={() => navigate("/dashboard")}
        onOverlayClick={() => navigate("/dashboard")}
      >
        <ModalOverlay />
        <ModalContent maxW="container.sm" mx={6}>
          <ModalHeader
            bgGradient="linear(to-br, teal.800, teal.600)"
            roundedTop="md"
            color="white"
          >
            Success!
          </ModalHeader>
          <ModalCloseButton color="white" />
          <ModalBody>
            <Stack align="center" pb={6}>
              <Text pt={3} align="center">
                We sent this client an invite on your behalf and scheduled a few
                reminders to help them get started.
              </Text>
              <Spacer h={6} />

              <Button
                colorScheme="teal"
                w="200px"
                as={ReactRouterLink}
                to="/clients"
                mb={6}
              >
                View All Clients
              </Button>
              <Spacer h={6} />
              <Button
                colorScheme="teal"
                w="200px"
                as={ReactRouterLink}
                to="/dashboard"
                mb={6}
              >
                View Dashboard
              </Button>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={{ base: "8", lg: "6" }}>
          <Box bg="bg-surface" boxShadow="sm" borderRadius="lg">
            <Stack
              spacing="5"
              px={{ base: "4", md: "6" }}
              py={{ base: "5", md: "6" }}
            >
              <Stack spacing="6" direction={{ base: "column", md: "row" }}>
                <FormControl id="firstName">
                  <FormLabel>First Name*</FormLabel>
                  <Input
                    defaultValue=""
                    isInvalid={errors?.firstName ? true : false}
                    {...register("firstName", { required: true })}
                  />
                  {errors.firstName && (
                    <Text pt={1} color="error" fontSize="sm" role="alert">
                      This field is required
                    </Text>
                  )}
                </FormControl>
                <FormControl id="lastName">
                  <FormLabel>Last Name*</FormLabel>
                  <Input
                    defaultValue=""
                    isInvalid={errors?.lastName ? true : false}
                    {...register("lastName", { required: true })}
                  />
                  {errors.lastName && (
                    <Text pt={1} color="error" fontSize="sm" role="alert">
                      This field is required
                    </Text>
                  )}
                </FormControl>
              </Stack>
              <Stack
                pt="4"
                spacing="6"
                direction={{ base: "column", md: "row" }}
              >
                <FormControl id="email">
                  <FormLabel>Email*</FormLabel>
                  <Input
                    defaultValue=""
                    isInvalid={errors?.email ? true : false}
                    {...register("email", {
                      validate: validateEmail,
                    })}
                  />
                  {errors.email && (
                    <Text pt={1} color="error" fontSize="sm" role="alert">
                      Enter a valid email address
                    </Text>
                  )}
                </FormControl>
                <FormControl id="phone">
                  <FormLabel>Phone*</FormLabel>
                  <InputGroup>
                    <InputLeftElement>
                      <Text>+1</Text>
                    </InputLeftElement>
                    <Input
                      as={InputMask}
                      mask="(999) 999-9999"
                      maskChar={"_"}
                      {...register("phone", { required: true })}
                    />
                  </InputGroup>
                  {errors.phone && (
                    <Text pt={1} color="error" fontSize="sm" role="alert">
                      This field is required
                    </Text>
                  )}
                </FormControl>
              </Stack>
              <Flex>
                <Box flex="2" me={{ base: 2, md: 6 }}>
                  <FormControl id="address">
                    <FormLabel>Address*</FormLabel>
                    <Autocomplete
                      apiKey={config.googlePlaces.apiKey}
                      onPlaceSelected={(selectedPlace) => {
                        console.log(selectedPlace);
                        setPlace(selectedPlace);
                      }}
                      style={{
                        width: "100%",
                        outline: "2px solid transparent",
                        outlineOffset: "2px",
                        position: "relative",
                        border: "1px solid",
                        padding: "7PX 11px",
                        borderRadius: "8px",
                        borderColor: errors.address ? "red" : "inherit",
                      }}
                      options={{
                        types: ["address"],
                        fields: ["address_components", "geometry", "place_id"],
                        componentRestrictions: { country: "us" },
                      }}
                      {...register("address")}
                    />
                    {errors.address && (
                      <Text pt={1} color="error" fontSize="sm" role="alert">
                        This field is required
                      </Text>
                    )}
                  </FormControl>
                </Box>
                <Box flex="1">
                  <FormControl id="unit">
                    <FormLabel>Unit</FormLabel>
                    <Input {...register("unit")} />
                  </FormControl>
                </Box>
              </Flex>

              <FormControl id="customInviteContent">
                <FormLabel>Personalized Email Message*</FormLabel>
                <Text fontSize="sm" pb="2">
                  Customize the message below. We'll include it in their invite
                  email.
                </Text>
                <Textarea
                  defaultValue="I'd love to have you join me on EasyHome! It's a great way to stay on top of everything you need to protect and maintain your property so you can just focus on enjoying your home to the fullest."
                  {...register("customInviteContent", { required: true })}
                />
                {errors.customInviteContent && (
                  <Text pt={1} color="error" fontSize="sm" role="alert">
                    This field is required
                  </Text>
                )}
              </FormControl>

              <FormControl id="agentNotes">
                <FormLabel>Notes to the EasyHome Concierge</FormLabel>
                <Text fontSize="sm" pb="2">
                  What should we know to better serve this client?
                </Text>
                <Textarea
                  defaultValue=""
                  placeholder="Is this a
                  new home purchase? Do they need help with some specific
                  projects? Etc."
                  {...register("agentNotes")}
                />
              </FormControl>

              <FormControl id="agentId">
                <Input
                  type="hidden"
                  value={userId || ""}
                  {...register("agentId")}
                />
              </FormControl>
            </Stack>
            <Divider />
            <Flex direction="row-reverse">
              <HStack spacing="4" p={{ base: "4", md: "6" }}>
                <Button
                  isLoading={isSubmitting}
                  variant="primary"
                  type="submit"
                >
                  Save & Invite Client
                </Button>
              </HStack>
            </Flex>
          </Box>
        </Stack>
      </form>
    </Stack>
  );
};
