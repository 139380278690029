import { Flex, Spinner, Container, Stack, Text } from "@chakra-ui/react";
import { PageHeader } from "../../components/PageHeader";
import { useState, useEffect } from "react";
import { IClientInvite } from "../../types/IClientInvite";
import { DocumentUploadForm } from "./DocumentUploadForm";
import { useRecoilValue } from "recoil";
import { userIdState } from "../../state/User";
import { useParams } from "react-router-dom";
import { collection, doc, getDoc, getDocs, query } from "firebase/firestore";
import { db } from "../../core/firebase";

export const DocumentUploads = () => {
  let { clientInviteId } = useParams();
  const userId = useRecoilValue(userIdState);
  const [client, setClient] = useState<IClientInvite | null>(null);
  const [clientDocs, setClientDocs] = useState<any[]>([]);

  useEffect(() => {
    const fetchClient = async (clientId: string, userId: string) => {
      const docRef = doc(db, "agents", userId, "client-invites", clientId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setClient(docSnap.data());

        const uploadsCollection = collection(
          db,
          "agents",
          userId,
          "client-invites",
          clientId,
          "uploads"
        );
        const q = query(uploadsCollection);
        const querySnapshot = await getDocs(q);
        const data = querySnapshot.docs.map((doc: any) => doc.data());
        setClientDocs(data);
      }
    };

    if (typeof clientInviteId !== "undefined" && userId !== null) {
      fetchClient(clientInviteId, userId);
    }
  }, [userId, clientInviteId]);

  if (client === null) {
    return (
      <Flex height="100vh" bg="bg-canvas" justify="center" align="center">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <Container>
      <PageHeader
        title="Upload Client Documents"
        subtitle={`${client.firstName} ${client.lastName} has requested documents for their home at ${client.address?.streetNumber} ${client.address?.streetShort} in ${client.address?.city}, ${client.address?.state}.`}
      />
      <DocumentUploadForm clientInvite={client} uploads={clientDocs} />
    </Container>
  );
};
