import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Container,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  StackDivider,
  Text,
  Link as ChakraLink,
  VStack,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from 'recoil';
import { userIdState, userProfileState } from '../../state/User';

import { Link, useNavigate } from 'react-router-dom';
import { saveUserProfile } from '../../services/ApiService';
import { useSendPasswordResetEmail } from 'react-firebase-hooks/auth';
import { auth } from '../../core/firebase';
import { useState } from 'react';

export const Settings = () => {
  const navigate = useNavigate();
  const userId = useRecoilValue(userIdState);
  const userProfile = useRecoilValue(userProfileState);
  const refreshUserProfile = useRecoilRefresher_UNSTABLE(userProfileState);
  const [sendPasswordResetEmail, resetLoading] =
    useSendPasswordResetEmail(auth);
  const [showResetSuccessMessage, setShowResetSuccessMessage] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm();

  const onSendPasswordReset = async () => {
    try {
      await sendPasswordResetEmail(userProfile.email);
      setShowResetSuccessMessage(true);
    } catch (error) {
      setError('root.submissionError', { message: 'An error occurred.' });
    }
  };

  const onSubmit = async (data: any) => {
    const userProfileUpdates = {
      firstName: data.firstName.trim(),
      lastName: data.lastName.trim(),
      phone: data.phone.trim(),
      updatedAt: new Date().toISOString(),
    };

    try {
      if (!userId) {
        throw new Error('User ID not found.');
      }
      await saveUserProfile(userId, userProfileUpdates);
      refreshUserProfile();
      navigate('/dashboard');
    } catch (error) {
      console.log(error);
      setError('root.submissionError', {
        message:
          'There was an error submitting your request. Please try again.',
      });
    }
  };

  return (
    <Container py={{ base: '4', md: '8' }}>
      <Stack spacing={{ base: '8', lg: '6' }}>
        <Stack
          pt='4'
          pb='4'
          spacing='4'
          direction={{ base: 'column', md: 'row' }}
          justify='space-between'
        >
          <Stack spacing='1'>
            <Heading size={{ base: 'xs', md: 'sm' }} fontWeight='medium'>
              General Settings
            </Heading>
          </Stack>
        </Stack>
        <Divider borderColor='gray.300' borderWidth={1} />

        <Box>
          <Heading size='xs'>Contact Info</Heading>
          <Text pt={2}>
            Let us know how to best keep in touch by keeping this information
            up-to-date.
          </Text>
        </Box>

        {errors.root?.submissionError && (
          <Alert status='error'>
            <AlertIcon />
            <AlertDescription>
              {errors.root?.submissionError.message}
            </AlertDescription>
          </Alert>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing='5'>
            <Stack spacing='3' divider={<StackDivider />}>
              <FormControl id='firstName'>
                <Stack
                  direction={{ base: 'column', md: 'row' }}
                  spacing={{ base: '1.5', md: '8' }}
                  justify='space-between'
                >
                  <FormLabel variant='inline'>First Name*</FormLabel>
                  <Input
                    maxW={{ md: '3xl' }}
                    defaultValue={userProfile?.firstName}
                    {...register('firstName', { required: true })}
                  />
                </Stack>
              </FormControl>
              <FormControl id='lastName'>
                <Stack
                  direction={{ base: 'column', md: 'row' }}
                  spacing={{ base: '1.5', md: '8' }}
                  justify='space-between'
                >
                  <FormLabel variant='inline'>Last Name*</FormLabel>
                  <Input
                    maxW={{ md: '3xl' }}
                    defaultValue={userProfile?.lastName}
                    {...register('lastName', { required: true })}
                  />
                </Stack>
              </FormControl>

              <FormControl id='phone'>
                <Stack
                  direction={{ base: 'column', md: 'row' }}
                  spacing={{ base: '1.5', md: '8' }}
                  justify='space-between'
                >
                  <FormLabel variant='inline'>Phone*</FormLabel>
                  <Input
                    maxW={{ md: '3xl' }}
                    defaultValue={userProfile?.phone}
                    {...register('phone', { required: true })}
                  />
                </Stack>
              </FormControl>
              <FormControl id='email'>
                <Stack
                  direction={{ base: 'column', md: 'row' }}
                  spacing={{ base: '1.5', md: '8' }}
                  justify='space-between'
                >
                  <FormLabel variant='inline'>Email</FormLabel>

                  <Input
                    type='email'
                    maxW={{ md: '3xl' }}
                    defaultValue={userProfile?.email}
                    isDisabled={true}
                  />
                </Stack>
              </FormControl>
              <FormControl id='userId'>
                <Text fontSize='sm' color='gray.500'>
                  Your email is also your EasyHome username.{' '}
                  <ChakraLink color='teal' href='mailto:info@easyhomeinc.com'>
                    Contact us
                  </ChakraLink>{' '}
                  to change it.
                </Text>
                <Input
                  type='hidden'
                  value={userId || ''}
                  {...register('userId', { required: true })}
                />
              </FormControl>

              <Flex direction='row-reverse'>
                <Stack spacing='4'>
                  <Button
                    isLoading={isSubmitting}
                    variant='primary'
                    type='submit'
                  >
                    Save Changes
                  </Button>
                </Stack>
              </Flex>
            </Stack>
          </Stack>
        </form>
        <Divider borderColor='gray.300' borderWidth={1} />

        <Box>
          <Heading size='xs'>Reset Password</Heading>
          <Text pt={2}>
            Click the button below to send a password reset email to{' '}
            <strong>{userProfile?.email}</strong>.
          </Text>
          <Button
            mt={4}
            variant='primary'
            onClick={onSendPasswordReset}
            isLoading={resetLoading}
          >
            Send Password Reset Email
          </Button>
        </Box>
        {showResetSuccessMessage && (
          <Alert status='success'>
            <AlertIcon />
            <AlertDescription>
              A password reset email has been sent to{' '}
              <strong>{userProfile?.email}</strong>. Please check your inbox.
            </AlertDescription>
          </Alert>
        )}

        <Divider borderColor='gray.300' borderWidth={1} />

        <Text fontSize='sm' lineHeight={1.3}>
          By using EasyHome Client Connect you agree to EasyHome's{' '}
          <ChakraLink
            as={Link}
            to='https://fixlehome.com/fixle-terms-of-use'
            color='teal.500'
            target='_blank'
          >
            Terms of Use
          </ChakraLink>{' '}
          and{' '}
          <ChakraLink
            color='teal.500'
            as={Link}
            to='https://fixlehome.com/fixle-privacy-notice'
            target='_blank'
          >
            Privacy Policy
          </ChakraLink>
          .
        </Text>
        <Text fontSize='sm' lineHeight={1.3}>
          © 2024 EasyHome, Inc. All rights reserved.
        </Text>
      </Stack>
    </Container>
  );
};
