import { Button, Heading, Stack, Text, Divider } from "@chakra-ui/react";
import { FC, ReactElement } from "react";

export interface PageHeaderProps {
  title: string;
  subtitle?: string;
  image?: string;
}

export const ImageBanner: FC<PageHeaderProps> = ({
  title,
  subtitle,
  image,
}) => {
  return (
    <Stack
      py="12"
      px="8"
      my="6"
      rounded={"lg"}
      spacing="4"
      direction={{ base: "column", md: "row" }}
      justify="space-between"
      backgroundImage={image}
      backgroundPosition="center center"
    >
      <Stack spacing="1">
        <Text
          color="white"
          fontSize={{ base: "lg", md: "xl" }}
          fontWeight="600"
        >
          {title}
        </Text>
        {subtitle && (
          <Text color="white" fontSize="lg" fontWeight="400">
            {subtitle}
          </Text>
        )}
      </Stack>
    </Stack>
  );
};
