import { db } from "../core/firebase";
import {
  collection,
  addDoc,
  setDoc,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { IAccessRequest } from "../types/IAccessRequest";
import { IAgentProfile } from "../types/IAgentProfile";
import { IClientInvite } from "../types/IClientInvite";

// handle new access request
export const saveAccessRequest = (accessRequest: IAccessRequest) => {
  // Save this to a firestore collection named access_requests
  return addDoc(collection(db, "access-requests"), accessRequest);
};

export const saveAgentProfile = (
  userId: string,
  agentProfile: IAgentProfile
) => {
  const profileRef = doc(db, "agents", userId, "shared", "profile");
  return setDoc(
    profileRef,
    {
      ...agentProfile,
    },
    { merge: true }
  );
};

export const saveUserProfile = (userId: string, updates: object) => {
  const userRef = doc(db, "users", userId);
  return setDoc(
    userRef,
    {
      ...updates,
    },
    { merge: true }
  );
};

export const saveClientInvite = (clientInvite: IClientInvite) => {
  console.log("saveClientInvite", clientInvite);
  if (
    clientInvite.ref != null &&
    typeof clientInvite.ref !== "undefined" &&
    clientInvite.ref.indexOf("client-invites") > -1
  ) {
    const inviteRef = doc(db, clientInvite.ref);
    return updateDoc(inviteRef, { ...clientInvite });
  }
};

export const createClientInvite = async (clientInvite: IClientInvite) => {
  if (clientInvite.agentId == null || clientInvite.agentId === "") {
    return false;
  }

  const docRef = doc(
    collection(db, `agents/${clientInvite.agentId}/client-invites`)
  );
  clientInvite.id = docRef.id;
  clientInvite.ref = docRef.path;
  clientInvite.isClaimed = false;
  clientInvite.isInvited = false;
  clientInvite.createdAt = new Date();

  await setDoc(docRef, clientInvite);
  return clientInvite;
};

export const updateClientInvite = async (clientInvite: IClientInvite) => {
  if (
    clientInvite.agentId == null ||
    clientInvite.agentId === "" ||
    clientInvite.id == null ||
    clientInvite.id === ""
  ) {
    return false;
  }

  const docRef = doc(
    db,
    `agents/${clientInvite.agentId}/client-invites/${clientInvite.id}`
  );

  // address can be null, if so, remove it from the object
  if (clientInvite.address == null) {
    delete clientInvite.address;
  }

  await updateDoc(docRef, { ...clientInvite });
  return clientInvite;
};

export const deleteClientInvite = async (
  clientInviteId: string,
  agentId: string
) => {
  if (clientInviteId === "" || agentId === "") {
    return false;
  }

  try {
    const docRef = doc(
      db,
      `agents/${agentId}/client-invites/${clientInviteId}`
    );
    await deleteDoc(docRef);
    return true;
  } catch (error) {
    console.log("error deleteClientInvite", error);
  }
};
