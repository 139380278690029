export type EnvName = "prod" | "test" | "local";
export type Config = {
  app: {
    env: EnvName;
    name: string;
    origin: string;
    hostname: string;
  };
  googlePlaces: {
    apiKey: string;
  };
  typesense: {
    host: string;
  };
  stripe: {
    giftPriceId: string;
  };
  firebase: {
    projectId: string;
    appId: string;
    apiKey: string;
    authDomain: string;
    measurementId: string;
    storageBucket: string;
    messagingSenderId: string;
  };
};

export const config: Config = {
  app: {
    env: (process.env.NODE_ENV as EnvName) || "local",
    name: process.env.REACT_APP_NAME || "",
    origin: process.env.REACT_APP_ORIGIN || "",
    hostname: process.env.REACT_APP_HOSTNAME || "",
  },
  googlePlaces: {
    apiKey: process.env.REACT_APP_GOOGLE_PLACES_API_KEY || "",
  },
  typesense: {
    host: process.env.REACT_APP_TYPESENSE_HOST || "",
  },
  stripe: {
    giftPriceId: process.env.REACT_APP_STRIPE_GIFT_PRICE_ID || "",
  },
  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY || "",
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "",
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || "",
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "",
    appId: process.env.REACT_APP_FIREBASE_APP_ID || "",
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || "",
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || "",
  },
};
