import {
  Box,
  Flex,
  Spinner,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { SideBar } from "./components/SideBar";
import { Navbar } from "./components/Navbar";

export const AppLayout = () => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });

  return (
    <Flex
      as="section"
      direction={{ base: "column", lg: "row" }}
      height="100vh"
      bg="bg-canvas"
      overflowY="auto"
    >
      <Suspense>{isDesktop ? <SideBar /> : <Navbar />}</Suspense>
      <Box
        p={{ base: 2, md: 4 }}
        bg={useColorModeValue("gray.100", "gray.900")}
        flex="1"
        overflowY="auto"
      >
        <Suspense
          fallback={
            <Flex height="100vh" bg="bg-canvas" justify="center" align="center">
              <Spinner size="xl" />
            </Flex>
          }
        >
          <Outlet />
        </Suspense>
      </Box>
    </Flex>
  );
};
