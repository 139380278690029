import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageEditor from "@pqina/filepond-plugin-image-editor";
import FilePondPluginFilePoster from "filepond-plugin-file-poster";
// import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
// import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import {
  openEditor,
  processImage,
  createDefaultImageReader,
  createDefaultImageWriter,
  // createDefaultImageOrienter,
  legacyDataToImageState,
  getEditorDefaults,
} from "@pqina/pintura";

import "filepond/dist/filepond.min.css";
import "@pqina/pintura/pintura.css";
import "filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css";
import "../theme/filepond-overrides.css";
import { useState } from "react";
import { ActualFileObject, FilePondInitialFile } from "filepond";
import { Box } from "@chakra-ui/react";
import { storage } from "../core/firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

registerPlugin(
  FilePondPluginFilePoster,
  FilePondPluginImageEditor,
  // FilePondPluginImagePreview,
  // FilePondPluginImageExifOrientation,
  FilePondPluginFileValidateType
);

interface IImageUploadParams {
  serverPath: string;
  allowMultiple?: boolean;
  maxFiles?: number;
  imagesOnly?: boolean;
  initialFiles?: FilePondInitialFile[];
  width?: number;
  onSave?: (file: ActualFileObject, remoteUrl: string) => void;
}

export function ImageUpload(params: IImageUploadParams) {
  const {
    serverPath,
    allowMultiple = true,
    maxFiles = 3,
    // imagesOnly = false,
    initialFiles = [],
    width = 60,
    onSave = () => {},
  } = params;
  const [files, setFiles] =
    useState<(string | Blob | ActualFileObject | FilePondInitialFile)[]>(
      initialFiles
    );
  return (
    <Box width={width}>
      <FilePond
        files={files}
        allowMultiple={allowMultiple}
        maxFiles={maxFiles}
        maxParallelUploads={1}
        labelIdle={`<span class="filepond--label-action">Choose a new image</span>`}
        onupdatefiles={(fileItems) => {
          if (fileItems.length === 0) {
            setFiles([]);
          }
          setFiles(fileItems.map((fileItem) => fileItem.file));
        }}
        credits={false}
        filePosterMaxHeight={256}
        imageEditor={{
          legacyDataToImageState: legacyDataToImageState,
          createEditor: openEditor,
          imageReader: [createDefaultImageReader, {}],
          imageWriter: [
            createDefaultImageWriter,
            {
              targetSize: {
                width: 500,
                height: 500,
              },
            },
          ],
          imageProcessor: processImage,
          editorOptions: {
            ...getEditorDefaults(),
            imageCropAspectRatio: 1,
            imageResizeTargetWidth: 500,
            imageResizeTargetHeight: 500,
            imageCropLimitToImage: false,
            imageBackgroundColor: [1, 1, 1],
          },
        }}
        imageEditorInstantEdit={true}
        server={{
          process: (
            fieldName,
            file,
            metadata,
            load,
            error,
            progress,
            abort
          ) => {
            // let uploadTask = setUp(fileData);
            let fName = `${new Date().getTime()}-${file.name}`;
            const storageRef = ref(storage, `${serverPath}/${fName}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
              "state_changed",
              (snapshot) => {
                progress(true, snapshot.bytesTransferred, snapshot.totalBytes);
              },
              (fileError) => {
                error(fileError.message);
              },
              async () => {
                // need to get the url to download the file
                let downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
                onSave(file, downloadUrl);
                load(file);
              }
            );
          },

          load: (source, load, error, progress, abort, headers) => {
            var myRequest = new Request(source);
            fetch(myRequest).then(function (response) {
              response.blob().then(function (myBlob) {
                load(myBlob);
              });
            });
          },

          // load: (source, load, error, progress, abort) => {
          //   //source is a string with the url of the file

          //   progress(true, 0, 1024);

          //   let xhr = new XMLHttpRequest();
          //   xhr.responseType = "blob";
          //   xhr.onload = function (event) {
          //     let blob = xhr.response;
          //     console.log("loaded URL: %s", source);
          //     load(blob);
          //   };
          //   xhr.open("GET", source);
          //   xhr.send();
          // },
        }}
      />
    </Box>
  );
}

// return (
//   <FilePond
//     files={files}
//     ref={ref}
//     labelIdle={`<span class="filepond--label-action"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24"><defs><path id="a" d="M24 24H0V0h24v24z"/></defs><clipPath id="b"><use xlink:href="#a" overflow="visible"/></clipPath><path clip-path="url(#b)" d="M3 4V1h2v3h3v2H5v3H3V6H0V4h3zm3 6V7h3V4h7l1.83 2H21c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2V10h3zm7 9c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-3.2-5c0 1.77 1.43 3.2 3.2 3.2s3.2-1.43 3.2-3.2-1.43-3.2-3.2-3.2-3.2 1.43-3.2 3.2z"/></svg><span>Add an image</span></span>`}
//     allowMultiple={false}
//     maxFiles={1}
//     server={{
//       process: (
//         _fieldName,
//         file,
//         _metadata,
//         load,
//         error,
//         progress,
//         _abort
//       ) => {
//         const id = shortid.generate();
//         const task = storage.child("images/" + id).put(file, {
//           contentType: "image/jpeg",
//         });

//         task.on(
//           firebase.storage.TaskEvent.STATE_CHANGED,
//           (snap) => {
//             log("progress: %o", snap);
//             progress(true, snap.bytesTransferred, snap.totalBytes);
//           },
//           (err) => {
//             log("error: %o", err);
//             error(err.message);
//           },
//           () => {
//             log("DONE");
//             load(id);
//             onRequestSave(id);
//           }
//         );
//       },
//       load: (source, load, error, progress, abort) => {
//         progress(true, 0, 1024);

//         storage
//           .child("images/" + source)
//           .getDownloadURL()
//           .then((url) => {
//             let xhr = new XMLHttpRequest();
//             xhr.responseType = "blob";
//             xhr.onload = function (event) {
//               let blob = xhr.response;
//               log("loaded URL: %s", url);
//               load(blob);
//             };
//             xhr.open("GET", url);
//             xhr.send();
//           })
//           .catch((err) => {
//             error(err.message);
//             abort();
//           });
//       },
//     }}
//     onupdatefiles={(fileItems) => {
//       if (fileItems.length === 0) {
//         onRequestClear();
//       }
//       setFiles(fileItems.map((fileItem) => fileItem.file));
//     }}
//   />
// );
// }
