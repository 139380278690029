import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  List,
  ListIcon,
  ListItem,
  Stack,
  Text,
} from "@chakra-ui/react";
import InputMask from "react-input-mask";
import { Controller, useForm } from "react-hook-form";
import { toErrorWithMessage } from "../../core/errors";
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from "recoil";
import {
  agentAlertsState,
  clientCountState,
  userIdState,
} from "../../state/User";
import { IAddress } from "../../types/IAddress";
import { IClientInvite } from "../../types/IClientInvite";
import { useNavigate } from "react-router-dom";
import React, { useState, ChangeEvent, FormEvent } from "react";
import Moment from "moment";
import { storage, db } from "../../core/firebase";
import {
  ref,
  getDownloadURL,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";
import {
  collection,
  addDoc,
  updateDoc,
  where,
  query,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore";
import { CheckCircleIcon } from "@chakra-ui/icons";

export const DocumentUploadForm = ({
  clientInvite,
  uploads,
}: {
  clientInvite: IClientInvite;
  uploads: any[];
}) => {
  const navigate = useNavigate();
  const userId = useRecoilValue(userIdState);
  const refreshAgentProfile = useRecoilRefresher_UNSTABLE(agentAlertsState);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm();

  const dismissAlert = async () => {
    try {
      const q = query(
        collection(db, "userActions"),
        where("userId", "==", userId),
        where("clientInviteId", "==", clientInvite.id)
      );
      const querySnapshot = await getDocs(q);
      const now = Moment.now();

      for (let i = 0; i < querySnapshot.docs.length; i++) {
        console.log(querySnapshot.docs[i].id);
        const newDoc = doc(db, `userActions/${querySnapshot.docs[i].id}`);
        // update the userAction with a new dismissedAt timestamp
        await updateDoc(newDoc, {
          dismissedAt: now,
          isDismissed: true,
        });
        refreshAgentProfile();
      }
    } catch (error) {
      alert("There was an error dismissing the alert.");
    }
  };

  const handleFormSubmit = async (data: any) => {
    if (!data.files || data.files.length === 0) {
      return;
    }
    try {
      // loop through each of the selected files and upload them to firebase storage
      for (let i = 0; i < data.files.length; i++) {
        const file = data.files[i];
        const storageRef = ref(
          storage,
          `agents/${userId}/clients/${clientInvite.id}/${file.name}`
        );
        const snapshot = await uploadBytes(storageRef, file);
        // add this file to the client's document uploads
        const downloadUrl = await getDownloadURL(snapshot.ref);
        await addDoc(
          collection(
            db,
            `agents/${userId}/client-invites/${clientInvite.id}/uploads`
          ),
          {
            name: file.name,
            url: downloadUrl,
            processedAt: null,
          }
        );
      }

      // get any userActions for this clientInvite and update them
      await dismissAlert();

      navigate(`/clients/docs/${clientInvite.id}/success`);
    } catch (error) {
      setError("root", {
        type: "manual",
        message: "There was an error uploading your files.",
      });
    }
  };

  return (
    <Stack spacing={{ base: "6", lg: "6" }}>
      {errors.root?.submissionError && (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>
            {errors.root?.submissionError.message}
          </AlertDescription>
        </Alert>
      )}

      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Stack spacing={{ base: "8", lg: "6" }}>
          <Box bg="bg-surface" boxShadow="sm" borderRadius="lg">
            <Stack
              spacing="5"
              px={{ base: "4", md: "6" }}
              py={{ base: "5", md: "6" }}
            >
              <Alert>
                <Text>
                  Typical files uploaded are closing statements, home
                  inspections, and project bids.
                </Text>
              </Alert>

              <Stack spacing="6" direction={{ base: "column", md: "row" }}>
                <FormControl id="files">
                  <FormLabel>Choose files</FormLabel>
                  <Input
                    multiple
                    type="file"
                    pt={1}
                    {...register("files", { required: true })}
                  />
                  {errors.files && (
                    <Text pt={1} color="error" fontSize="sm" role="alert">
                      This field is required
                    </Text>
                  )}
                </FormControl>
                <FormControl id="agentId">
                  <Input
                    type="hidden"
                    value={userId || ""}
                    {...register("agentId")}
                  />
                </FormControl>
                <FormControl id="clientInviteId">
                  <Input
                    type="hidden"
                    value={clientInvite.id || ""}
                    {...register("clientInviteId")}
                  />
                </FormControl>
              </Stack>
            </Stack>
            <Divider />
            <Flex direction="row-reverse">
              <HStack spacing="4" p={{ base: "4", md: "6" }}>
                <Button
                  isLoading={isSubmitting}
                  variant="primary"
                  type="submit"
                >
                  Upload Documents
                </Button>
              </HStack>
            </Flex>
          </Box>
          {uploads.length > 0 && (
            <>
              <Text fontSize="md" fontWeight={"bold"}>
                You've already uploaded the following files for this client:
              </Text>
              <List>
                {uploads.map((upload) => (
                  <ListItem key={upload.name}>
                    <ListIcon as={CheckCircleIcon} color="green.500" />
                    {upload.name}
                  </ListItem>
                ))}
              </List>
            </>
          )}
          {uploads.length === 0 && (
            <HStack>
              <Text>Don't have files for this home?</Text>
              <Button
                onClick={async () => {
                  await dismissAlert();
                  navigate(`/dashboard`);
                }}
              >
                Close this request
              </Button>
            </HStack>
          )}
        </Stack>
      </form>
    </Stack>
  );
};
