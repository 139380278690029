import {
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  useDisclosure,
} from "@chakra-ui/react";
import { Logo } from "./Logo";
import { SideBar } from "./SideBar";
import { ToggleButton } from "./ToggleButton";

export const NavbarWithoutDrawer = () => {
  return (
    <Box width="full" py="4" px={{ base: "4", md: "8" }} bg="gray.800">
      <Flex justify="space-between" align={"center"}>
        <Logo />
      </Flex>
    </Box>
  );
};
