import { Button, Container, Stack, HStack, Image } from "@chakra-ui/react";
import { PageHeader } from "../../components/PageHeader";
import { useParams, Link } from "react-router-dom";

export const DocumentUploadSuccess = () => {
  let { clientInviteId } = useParams();

  return (
    <Container>
      <PageHeader
        title="Uploaded Successfully"
        subtitle="Thanks for the upload! We'll let your client know they have new files."
      />
      <Stack pb={6} maxW="container.md" spacing={12} align="center">
        <HStack>
          <Button w="250" colorScheme="teal">
            <Link to="/dashboard">Back to Dashboard</Link>
          </Button>
          <Button w="250" variant="outline">
            <Link to={`/clients/docs/${clientInviteId}`}>Upload More Docs</Link>
          </Button>
        </HStack>
        <Image src="/assets/high-five.svg" alt="" />
      </Stack>
    </Container>
  );
};
