import {
  getCurrentUserSubscriptions,
  getProducts,
  Subscription,
} from "@stripe/firestore-stripe-payments";
import { selector } from "recoil";
import { payments } from "../core/stripe";
import { userIdState } from "./User";

export const productListQuery = selector({
  key: "ProductListQuery",
  get: async () => {
    const products = await getProducts(payments, {
      includePrices: true,
      activeOnly: true,
      where: [["name", "==", "ClientConnect Subscription"]],
    });

    return products;
  },
});

export const activeSubscriptionQuery = selector<Subscription[]>({
  key: "ActiveSubscriptionQuery",
  get: async ({ get }) => {
    const userID = get(userIdState);
    if (userID) {
      const subscriptions = await getCurrentUserSubscriptions(payments, {
        status: ["active", "trialing"],
      });
      return subscriptions;
    }

    return [];
  },
});
