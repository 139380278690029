import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  StackProps,
  Text,
  Link as ChakraLink,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { PasswordField } from '../../components/PasswordField';
import { auth, functions } from '../../core/firebase';
import { httpsCallable, HttpsCallableResult } from 'firebase/functions';
import { useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { UserCredential } from 'firebase/auth';

interface IConfirmAccountProps extends StackProps {
  maskedEmail: string;
}

export const ConfirmAccountForm = (props: IConfirmAccountProps) => {
  const { maskedEmail } = props;
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm();
  const navigate = useNavigate();
  let { id } = useParams();
  const [signIn] = useSignInWithEmailAndPassword(auth);

  const onSubmit = async (data: any) => {
    try {
      if (data.password.trim().length < 8) {
        throw Error('Password must be at least 8 characters');
      }
      const createUserFromRequest = httpsCallable(
        functions,
        'clientConnect-createUserFromRequest'
      );
      const createUserResult: HttpsCallableResult = await createUserFromRequest(
        {
          accessRequestId: id,
          email: data.email.toLowerCase().trim(),
          password: data.password.trim(),
        }
      );

      if (createUserResult.data === true) {
        // sign in and go to the dashboard
        const signInResult: UserCredential | undefined = await signIn(
          data.email.toLowerCase().trim(),
          data.password.trim()
        );

        if (signInResult?.user.uid) {
          navigate('/dashboard');
        } else {
          navigate('/auth/sign-in');
        }
      } else {
        setError('root.submissionError', {
          message:
            "We couldn't verify the email you submitted. Please try again.",
        });
      }
    } catch (error: any) {
      if (error != null && typeof error === 'object') {
        setError('root.submissionError', {
          message: error.message || 'An error occurred.',
        });
      } else {
        setError('root.submissionError', { message: 'An error occurred.' });
      }
    }
  };

  return (
    <Box
      py={{ base: '0', sm: '8' }}
      px={{ base: '4', sm: '10' }}
      bg={{ base: 'transparent', sm: 'bg-surface' }}
      boxShadow={{ base: 'none', sm: 'md' }}
      borderRadius={{ base: 'none', sm: 'xl' }}
    >
      <Stack spacing='6'>
        {errors.root && (
          <Alert status='error'>
            <AlertIcon />
            <AlertDescription>
              {errors.root.submissionError.message}
            </AlertDescription>
          </Alert>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing='5'>
            <Alert status='info' variant='subtle'>
              <AlertIcon />
              <AlertDescription fontSize='sm' lineHeight='sm'>
                We'll use your email on file to secure your account. Once
                confirmed, you can change it in your account settings.
              </AlertDescription>
            </Alert>
            <FormControl id='email'>
              <FormLabel>Confirm your email "{maskedEmail}"</FormLabel>
              <Input
                type='email'
                placeholder='confirm email'
                isInvalid={errors?.email ? true : false}
                {...register('email', {
                  required: 'Email is required',
                })}
              />
              {errors.email && (
                <Text pt={1} color='error' fontSize='sm' role='alert'>
                  This field is required
                </Text>
              )}
            </FormControl>
            <PasswordField
              {...register('password')}
              title='Choose a password'
            />
            <Text fontSize='xs' lineHeight={1.3}>
              By clicking "Secure My Account" you agree to EasyHome's{' '}
              <ChakraLink
                as={Link}
                to='https://fixlehome.com/fixle-terms-of-use'
                color='teal.500'
                target='_blank'
              >
                Terms of Use
              </ChakraLink>{' '}
              and{' '}
              <ChakraLink
                color='teal.500'
                as={Link}
                to='https://fixlehome.com/fixle-privacy-notice'
                target='_blank'
              >
                Privacy Policy
              </ChakraLink>
              .
            </Text>
            <Button variant='primary' isLoading={isSubmitting} type='submit'>
              Secure My Account
            </Button>
          </Stack>
        </form>
      </Stack>
    </Box>
  );
};
