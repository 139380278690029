import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import InputMask from "react-input-mask";
import { useForm } from "react-hook-form";
import { toErrorWithMessage } from "../../core/errors";
import { useRecoilValue } from "recoil";
import { updateClientInvite } from "../../services/ApiService";
import { userIdState } from "../../state/User";
import Autocomplete from "react-google-autocomplete";
import { IAddress } from "../../types/IAddress";
import { IClientInvite } from "../../types/IClientInvite";
import { useState } from "react";
import { config } from "../../core/config";
import { useNavigate } from "react-router-dom";
import { validateEmail } from "../../core/validation";
import { formatGooglePlaceAddress } from "../../core/addressUtils";

export const EditClientForm = ({
  clientInvite,
}: {
  clientInvite: IClientInvite;
}) => {
  const navigate = useNavigate();
  const [place, setPlace] = useState<any>(null);
  const userId = useRecoilValue(userIdState);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm();

  const formatAddress = (address?: IAddress) => {
    if (address) {
      const returnAddress = formatGooglePlaceAddress(address);
      return returnAddress;
    }
    return "";
  };

  const onSubmit = async (data: any) => {
    try {
      let address: IAddress | null = null;
      if (place !== null) {
        // sometime the city (locality) is null, make sure we have a fallback
        let city = place.address_components?.find(
          (c: any) => c.types[0] === "locality"
        )?.long_name;
        if (typeof city === "undefined") {
          city = place.address_components?.find(
            (c: any) => c.types[0] === "sublocality_level_1"
          )?.long_name;
        }

        address = {
          googlePlaceId: place.place_id,
          streetNumber: place.address_components?.find(
            (c: any) => c.types[0] === "street_number"
          )?.long_name,
          streetShort: place.address_components?.find(
            (c: any) => c.types[0] === "route"
          )?.short_name,
          streetLong: place.address_components?.find(
            (c: any) => c.types[0] === "route"
          )?.long_name,
          city: city,
          state: place.address_components?.find(
            (c: any) => c.types[0] === "administrative_area_level_1"
          )?.short_name,
          postalCode: place.address_components?.find(
            (c: any) => c.types[0] === "postal_code"
          )?.long_name,
          latitude: place.geometry?.location.lat(),
          longitute: place.geometry?.location.lng(),
          unit: data.unit,
        };
        if (address.state !== "OR") {
          throw new Error(
            "EasyHome is only serving homes in Oregon at this time. Please enter a different address."
          );
        }
        if (
          typeof address.streetNumber === "undefined" ||
          address.streetNumber === null
        ) {
          throw new Error(
            "The seleted address is missing a street number. Please try a different address."
          );
        }
      }

      const newClientInvite = {
        id: data.clientInviteId,
        agentId: data.agentId,
        firstName: data.firstName.trim(),
        lastName: data.lastName.trim(),
        email: data.email.toLowerCase().trim(),
        phone: data.phone.trim(),
        address: address,
        customInviteContent: data.customInviteContent.trim(),
        agentNotes: data.agentNotes.trim(),
      } as IClientInvite;
      const clientInviteResult = await updateClientInvite(newClientInvite);
      if (clientInviteResult === false) {
        throw new Error("Failed to update client invite");
      }

      navigate("/clients");
    } catch (error) {
      const errorWithMessage = toErrorWithMessage(error);
      setError("root.submissionError", { message: errorWithMessage.message });
    }
  };

  return (
    <Stack spacing={{ base: "6", lg: "6" }}>
      {errors.root?.submissionError && (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>
            {errors.root?.submissionError.message}
          </AlertDescription>
        </Alert>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={{ base: "8", lg: "6" }}>
          <Box bg="bg-surface" boxShadow="sm" borderRadius="lg">
            <Stack
              spacing="5"
              px={{ base: "4", md: "6" }}
              py={{ base: "5", md: "6" }}
            >
              <Stack spacing="6" direction={{ base: "column", md: "row" }}>
                <FormControl id="firstName">
                  <FormLabel>First Name*</FormLabel>
                  <Input
                    defaultValue={clientInvite.firstName}
                    isInvalid={errors?.firstName ? true : false}
                    {...register("firstName", { required: true })}
                  />
                  {errors.firstName && (
                    <Text pt={1} color="error" fontSize="sm" role="alert">
                      This field is required
                    </Text>
                  )}
                </FormControl>
                <FormControl id="lastName">
                  <FormLabel>Last Name*</FormLabel>
                  <Input
                    defaultValue={clientInvite.lastName}
                    isInvalid={errors?.lastName ? true : false}
                    {...register("lastName", { required: true })}
                  />
                  {errors.lastName && (
                    <Text pt={1} color="error" fontSize="sm" role="alert">
                      This field is required
                    </Text>
                  )}
                </FormControl>
              </Stack>
              <Stack
                pt="4"
                spacing="6"
                direction={{ base: "column", md: "row" }}
              >
                <FormControl id="email">
                  <FormLabel>Email*</FormLabel>
                  <Input
                    defaultValue={clientInvite.email}
                    isInvalid={errors?.email ? true : false}
                    {...register("email", {
                      validate: validateEmail,
                    })}
                  />
                  {errors.email && (
                    <Text pt={1} color="error" fontSize="sm" role="alert">
                      Enter a valid email address
                    </Text>
                  )}
                </FormControl>
                <FormControl id="phone">
                  <FormLabel>Phone*</FormLabel>
                  <InputGroup>
                    <InputLeftElement>
                      <Text>+1</Text>
                    </InputLeftElement>
                    <Input
                      as={InputMask}
                      defaultValue={clientInvite.phone}
                      mask="(999) 999-9999"
                      maskChar={"_"}
                      {...register("phone", { required: true })}
                    />
                  </InputGroup>
                  {errors.phone && (
                    <Text pt={1} color="error" fontSize="sm" role="alert">
                      This field is required
                    </Text>
                  )}
                </FormControl>
              </Stack>
              <Flex>
                <Box flex="2" me={{ base: 2, md: 6 }}>
                  <FormControl id="address">
                    <FormLabel>Address*</FormLabel>
                    <Autocomplete
                      apiKey={config.googlePlaces.apiKey}
                      onPlaceSelected={(selectedPlace) => {
                        setPlace(selectedPlace);
                      }}
                      style={{
                        width: "100%",
                        outline: "2px solid transparent",
                        outlineOffset: "2px",
                        position: "relative",
                        border: "1px solid",
                        padding: "7PX 11px",
                        borderRadius: "8px",
                        borderColor: errors.address ? "red" : "inherit",
                      }}
                      options={{
                        types: ["address"],
                        fields: ["address_components", "geometry", "place_id"],
                        componentRestrictions: { country: "us" },
                      }}
                      {...register("address")}
                      defaultValue={formatAddress(clientInvite?.address)}
                    />
                    {errors.address && (
                      <Text pt={1} color="error" fontSize="sm" role="alert">
                        This field is required
                      </Text>
                    )}
                  </FormControl>
                </Box>
                <Box flex="1">
                  <FormControl id="unit">
                    <FormLabel>Unit</FormLabel>
                    <Input
                      defaultValue={clientInvite.address?.unit}
                      {...register("unit")}
                    />
                  </FormControl>
                </Box>
              </Flex>

              <FormControl id="customInviteContent">
                <FormLabel>Personalized Email Message*</FormLabel>
                <Text fontSize="sm" pb="2">
                  Customize the message below. We'll include it in their invite
                  email.
                </Text>
                <Textarea
                  defaultValue={clientInvite.customInviteContent}
                  {...register("customInviteContent", { required: true })}
                />
                {errors.customInviteContent && (
                  <Text pt={1} color="error" fontSize="sm" role="alert">
                    This field is required
                  </Text>
                )}
              </FormControl>

              <FormControl id="agentNotes">
                <FormLabel>Notes to the EasyHome Concierge</FormLabel>
                <Text fontSize="sm" pb="2">
                  What should we know to better serve this client?
                </Text>
                <Textarea
                  defaultValue={clientInvite.agentNotes}
                  placeholder="Is this a
                  new home purchase? Do they need help with some specific
                  projects? Etc."
                  {...register("agentNotes")}
                />
              </FormControl>

              <FormControl id="agentId">
                <Input
                  type="hidden"
                  value={userId || ""}
                  {...register("agentId")}
                />
              </FormControl>
              <FormControl id="clientInviteId">
                <Input
                  type="hidden"
                  value={clientInvite.id || ""}
                  {...register("clientInviteId")}
                />
              </FormControl>
            </Stack>
            <Divider />
            <Flex direction="row-reverse">
              <HStack spacing="4" p={{ base: "4", md: "6" }}>
                <Button
                  isLoading={isSubmitting}
                  variant="primary"
                  type="submit"
                >
                  Update Client Invite
                </Button>
              </HStack>
            </Flex>
          </Box>
        </Stack>
      </form>
    </Stack>
  );
};
