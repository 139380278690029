import { BoxProps, Container } from "@chakra-ui/react";
import { PageHeader } from "../../components/PageHeader";
import { AddClientSteps } from "./components/steps/AddClientSteps";
import { addClientSteps } from "./components/steps/data";
import { useStep } from "../../components/Steps/useStep";
// import { AddClientFormContactInfo } from "./forms/ContactInfo";
// import { AddClientFormDocuments } from "./forms/Documents";
// import { AddClientFormInvite } from "./forms/Invite";
import { useState } from "react";
import { IClientInvite } from "../../types/IClientInvite";
import { AddClientForm } from "./AddClientForm";
// import { AddClientFormPropertyDetails } from "./forms/PropertyDetails";

// export interface AddClientFormProps extends BoxProps {
//   setStep: (step: number) => void;
//   clientInvite: IClientInvite | null;
//   setClientInvite: (clientInvite: IClientInvite | null) => void;
// }

export const AddClient = (props: BoxProps) => {
  // const [currentStep, { setStep }] = useStep({
  //   maxStep: addClientSteps.length,
  //   initialStep: 0,
  // });

  // const [clientInvite, setClientInvite] = useState<IClientInvite | null>(null);

  return (
    <Container>
      <PageHeader
        title="Invite A Client"
        subtitle="Fill out all the required fields and submit to send your client a
          personalized invitation to the EasyHome app."
      />
      <AddClientForm />

      {/* <AddClientSteps
        currentStep={currentStep}
        steps={addClientSteps}
        setStep={setStep}
      />

      {currentStep === 0 && (
        <AddClientFormContactInfo
          setStep={setStep}
          clientInvite={clientInvite}
          setClientInvite={setClientInvite}
        />
      )}
      {currentStep === 1 && (
        <AddClientFormDocuments
          setStep={setStep}
          clientInvite={clientInvite}
          setClientInvite={setClientInvite}
        />
      )}
      {currentStep === 2 && (
        <AddClientFormInvite
          setStep={setStep}
          clientInvite={clientInvite}
          setClientInvite={setClientInvite}
        />
      )} */}
    </Container>
  );
};
