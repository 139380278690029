import {
  Container,
  Heading,
  HStack,
  Link,
  Spinner,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { httpsCallable } from "firebase/functions";
import { useContext, useEffect, useState } from "react";
import { useParams, Link as ReactRouterLink } from "react-router-dom";
import { auth, functions } from "../../core/firebase";
import { Logo } from "../../layout/components/Logo";
import { ConfirmAccountForm } from "./ConfirmAccountForm";
import { ConfirmAccountSignInForm } from "./ConfirmAccountSignInForm";
import { UserIdContext } from "../Root";

const ConfirmAccount = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const [name, setName] = useState("");
  const [maskedEmail, setMaskedEmail] = useState("");
  const [hasAccount, setHasAccount] = useState(false);
  const userId = useContext(UserIdContext);

  useEffect(() => {
    if (userId) {
      auth.signOut();
    }
    const checkRequestStatus = httpsCallable(
      functions,
      "clientConnect-checkRequestStatus"
    );
    checkRequestStatus({ accessRequestId: id }).then((result) => {
      const data: unknown = result.data;
      setIsLoading(false);
      if (data === false) {
        setIsValid(false);
      } else if (data !== null && typeof data === "object") {
        const obj = data as {
          firstName: string;
          approved: boolean;
          maskedEmail: string;
          hasAccount: boolean;
        };
        setIsValid(obj.approved);
        setName(obj.firstName);
        setMaskedEmail(obj.maskedEmail);
        setHasAccount(obj.hasAccount);
      }
    });
  }, [id]);

  return (
    <Container
      maxW="lg"
      py={{ base: "12", md: "24" }}
      px={{ base: "0", sm: "8" }}
    >
      <Stack spacing="8">
        <Stack spacing="12" align="center">
          <Logo />

          {isLoading && (
            <Stack spacing={{ base: "2", md: "6" }} textAlign="center">
              <Text fontWeight="bold" color="muted">
                One moment while we confirm your invite...
              </Text>
              <Spinner />
            </Stack>
          )}
          {isValid && (
            <Stack spacing={{ base: "2", md: "6" }} textAlign="center">
              <Heading size={{ base: "2xs", md: "xs" }}>
                Hi {name}, welcome to EasyHome Client Connect!
              </Heading>
              {hasAccount && (
                <VStack spacing="1" justify="center">
                  <ConfirmAccountSignInForm maskedEmail={maskedEmail} />
                </VStack>
              )}
              {!hasAccount && (
                <VStack spacing="1" justify="center">
                  <Text fontWeight="bold" color="muted">
                    Let's start by securing your account.
                  </Text>
                  <ConfirmAccountForm maskedEmail={maskedEmail} />
                </VStack>
              )}
            </Stack>
          )}
          {!isLoading && !isValid && (
            <Stack spacing={{ base: "2", md: "6" }} textAlign="center">
              <Text fontWeight="bold" color="muted">
                Sorry, this invite link isn't valid. Reach out to us at{" "}
                <a href="mailto:concierge@easyhomeinc.com">
                  concierge@easyhomeinc.com
                </a>{" "}
                for support.
              </Text>
              <Text>
                Already have an account?{" "}
                <Link color="teal" as={ReactRouterLink} to="/auth/sign-in">
                  Sign In
                </Link>
              </Text>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Container>
  );
};

export default ConfirmAccount;
