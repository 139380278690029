import { useRouteError } from "react-router-dom";
import { Container, Heading, HStack, Stack, Text } from "@chakra-ui/react";
import { Logo } from "./components/Logo";

export function RootError(): JSX.Element {
  const err = useRouteError() as RouteError;

  return (
    <Container
      maxW="lg"
      py={{ base: "12", md: "24" }}
      px={{ base: "0", sm: "8" }}
    >
      <Stack spacing="8">
        <Stack spacing="6">
          <Logo />
          <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
            <Heading size={{ base: "xs", md: "sm" }}>
              Error {err.status || 500}
            </Heading>
            <HStack spacing="1" justify="center">
              <Text color="muted">{err.statusText ?? err.message}</Text>
            </HStack>
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
}

type RouteError = Error & { status?: number; statusText?: string };
