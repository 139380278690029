import { Button, Heading, Stack, Text } from "@chakra-ui/react";
import { FC, ReactElement } from "react";

export interface PageHeaderProps {
  title: string;
  subtitle?: string;
  image?: string;
}

export const PageHeader: FC<PageHeaderProps> = ({ title, subtitle, image }) => {
  return (
    <Stack
      pt="4"
      pb="8"
      spacing="4"
      direction={{ base: "column", md: "row" }}
      justify="space-between"
      backgroundImage={image}
    >
      <Stack spacing="1" w={{ base: "100%", md: "container.md" }}>
        <Text fontSize={{ base: "lg", md: "xl" }} fontWeight="600">
          {title}
        </Text>
        {subtitle && <Text color="muted">{subtitle}</Text>}
      </Stack>
    </Stack>
  );
};
