import {
  extendTheme,
  theme as baseTheme,
  SystemStyleObject,
} from "@chakra-ui/react";
import { theme as proTheme } from "@chakra-ui/pro-theme";
import "@fontsource/inter/variable.css";

const globalStyles: SystemStyleObject = {
  // See https://github.com/chakra-ui/chakra-ui/blob/cc0598ddcb2a4e7f84859099556c228c5ff354fe/packages/components/css-reset/src/css-reset.tsx#L3
  "@supports (height: 100dvh)": {
    ":root": {
      "--chakra-vh": "100dvh",
    },
  },
};

export const theme = extendTheme(
  {
    colors: { ...baseTheme.colors, brand: baseTheme.colors.teal },
    initialColorMode: "light",
    useSystemColorMode: false,
    styles: {
      global: globalStyles,
    },
  },
  proTheme
);
