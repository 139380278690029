import {
  Avatar,
  Badge,
  Box,
  Button,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Spinner,
  Tooltip,
  Tr,
  VStack,
  useBreakpointValue,
  useToast,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Spacer,
} from "@chakra-ui/react";
import { useRef, useState, useEffect } from "react";
import moment from "moment";
import { formatGooglePlaceAddressFromSearch } from "../../../core/addressUtils";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import { BiDownArrowCircle, BiDotsVerticalRounded } from "react-icons/bi";
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from "recoil";
import { connectStateResults } from "react-instantsearch-dom";
import { StateResultsProvided, type BasicDoc } from "react-instantsearch-core";
import { CheckIcon } from "@chakra-ui/icons";
import { deleteClientInvite } from "../../../services/ApiService";
import { clientCountState, userIdState } from "../../../state/User";
import {
  collection,
  doc,
  orderBy,
  where,
  getDocs,
  query,
  type DocumentSnapshot,
} from "firebase/firestore";
import { useCreateCheckoutSessionForGift } from "../../../core/stripe";
import { db } from "../../../core/firebase";
import { IClientInvite } from "../../../types/IClientInvite";

const SearchResultTable = (props: StateResultsProvided<BasicDoc>) => {
  const { searchResults } = props;
  const isDesktop = useBreakpointValue({ base: false, xl: true });
  const userId = useRecoilValue(userIdState);
  const navigate = useNavigate();
  const [clients, setClients] = useState<any[]>([]);
  const hasResults = searchResults && searchResults.nbHits !== 0;
  const hits = searchResults && searchResults.hits;
  const [refreshClients, setRefreshClients] = useState(false);

  useEffect(() => {
    const fetchClients = async (clientIds: any[]) => {
      const invites = query(
        collection(db, `agents/${userId}/client-invites`),
        where("id", "in", clientIds),
        orderBy("createdAt", "desc")
      );
      const querySnapshot = await getDocs(invites);
      const data = querySnapshot.docs.map((doc: any) => doc.data());
      setClients(data);
      setRefreshClients(false);
    };

    if (!hits) return;
    if (hits?.length > 0) {
      const clientIds = hits.map((hit: any) => hit.id);
      fetchClients(clientIds);
    } else {
      setClients([]);
    }
  }, [hits, userId, refreshClients]);

  if (!isDesktop) {
    return (
      <VStack px={4} pb={4} spacing={3} w="100%" align="flex-start">
        <HStack w="100%" justify="flex-end">
          <Text fontSize="sm" textAlign="right">
            Sorted by invite date
          </Text>
          <BiDownArrowCircle />
        </HStack>
        {clients?.length > 0 &&
          clients.map((member) => {
            return (
              <Box
                w="100%"
                key={member.id}
                bgGradient="linear(to-br, gray.100, white)"
                p={6}
                rounded="xl"
              >
                <Text color="black" fontWeight={600}>
                  {member.firstName} {member.lastName}
                </Text>

                <Text fontSize="sm" color="muted">
                  {member.email}
                </Text>

                <Text fontSize="sm" color="muted">
                  {`${member.address?.streetNumber} ${member.address?.streetShort}`}
                </Text>
                <Text fontSize="sm" color="muted">
                  {`${member.address?.city}, ${member.address?.state} ${member.address?.postalCode}`}
                </Text>

                <Text fontSize="sm" color="muted">
                  <span style={{ fontWeight: 600 }}>Date Invited: </span>
                  {member.lastInvitedAt?.seconds &&
                    moment
                      .unix(member.lastInvitedAt?.seconds)
                      .format("MM/DD/YYYY")}
                  {!member.lastInvitedAt?.seconds && "Invite Pending"}
                </Text>

                <Text fontSize="sm" color="muted">
                  <span style={{ fontWeight: 600 }}>Date Claimed: </span>
                  {member.claimedAt?.seconds &&
                    moment.unix(member.claimedAt?.seconds).format("MM/DD/YYYY")}
                  {!member.claimedAt?.seconds && "Not Onboarded"}
                </Text>
                <Text fontSize="sm" color="muted">
                  <span style={{ fontWeight: 600 }}>Bookings: </span>
                  {member.bookingCount || "-"}
                </Text>
                <Spacer h={3} />
                <ClientActions
                  clientInvite={member}
                  setRefreshClients={setRefreshClients}
                />
              </Box>
            );
          })}
      </VStack>
    );
  } else {
    return (
      <>
        <Table>
          <Thead>
            <Tr>
              <Th>Client</Th>
              <Th>Address</Th>
              <Th>Date Invited</Th>
              <Th>Claimed</Th>
              <Th>Bookings</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {clients?.length > 0 ? (
              clients.map((hit: any) => (
                <Tr key={hit.id}>
                  <Td>
                    <Text fontWeight="bold">
                      {hit.firstName} {hit.lastName}
                    </Text>
                    <Text color="muted">{hit.email}</Text>
                  </Td>

                  <Td>
                    <Text color="muted">
                      {`${hit.address?.streetNumber} ${hit.address?.streetShort}`}
                    </Text>
                    <Text color="muted">
                      {`${hit.address?.city}, ${hit.address?.state} ${hit.address?.postalCode}`}
                    </Text>
                  </Td>
                  <Td>
                    <Text color="muted">
                      {moment
                        .unix(hit.lastInvitedAt?.seconds)
                        .format("MM/DD/YYYY")}
                    </Text>
                  </Td>
                  <Td>
                    <Text color="muted">
                      {hit.isClaimed === true ? <CheckIcon /> : "-"}
                    </Text>
                  </Td>
                  <Td>
                    <Text>{hit.bookingCount || "-"}</Text>
                  </Td>
                  <Td>
                    <ClientActions
                      clientInvite={hit}
                      setRefreshClients={setRefreshClients}
                    />
                  </Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td>No results</Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </>
    );
  }
};

interface IClientActionsProps {
  clientInvite: IClientInvite;
  setRefreshClients: Function;
}
const ClientActions = (props: IClientActionsProps) => {
  const { clientInvite, setRefreshClients } = props;
  const userId = useRecoilValue(userIdState);
  const [giftButtonIsBusy, setGiftButtonIsBusy] = useState(false);
  const [docsButtonIsBusy, setDocsButtonIsBusy] = useState(false);
  const createCheckoutSessionForGift = useCreateCheckoutSessionForGift();
  const [currentClientId, setCurrentClientId] = useState<string | null>(null);
  const cancelConfirmDeleteRef = useRef<HTMLButtonElement>(null);
  const toast = useToast();
  const navigate = useNavigate();
  const refreshClientCount = useRecoilRefresher_UNSTABLE(clientCountState);
  const {
    isOpen: isConfirmDeleteOpen,
    onOpen: onConfirmDeleteOpen,
    onClose: onConfirmDeleteClose,
  } = useDisclosure();
  const handleSendGiftClick = async (id: string) => {
    const session = await createCheckoutSessionForGift(id);
    window.location.assign(session.url);
  };

  return (
    <HStack>
      {!clientInvite.claimedAt ? (
        <Tooltip
          label={`You can send this client a gift once they claim your invite.`}
        >
          <Button size="sm" isDisabled={true}>
            Send Gift
          </Button>
        </Tooltip>
      ) : (
        <Button
          size="sm"
          colorScheme="teal"
          onClick={async () => {
            setGiftButtonIsBusy(true);
            handleSendGiftClick(clientInvite.id ?? "");
          }}
          disabled={giftButtonIsBusy}
        >
          {giftButtonIsBusy ? <Spinner size="sm" /> : "Send Gift"}
        </Button>
      )}

      {clientInvite.requestAgentFiles !== true ? (
        <Tooltip label={`This client hasn't requested any documents.`}>
          <Button size="sm" isDisabled={true}>
            Upload Docs
          </Button>
        </Tooltip>
      ) : (
        <Button
          size="sm"
          colorScheme="teal"
          onClick={async () => {
            navigate(`/clients/docs/${clientInvite.id}`);
          }}
        >
          Upload Docs
        </Button>
      )}

      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<BiDotsVerticalRounded />}
          variant="outline"
        />
        <MenuList>
          <MenuItem
            onClick={() => {
              navigate(`/clients/edit/${clientInvite.id}`);
            }}
          >
            Edit Client
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigator.clipboard.writeText(
                `https://app.easyhomeinc.com/invite/${clientInvite.id}`
              );

              toast({
                title: "Invite link copied to clipboard",
                status: "success",
              });
            }}
          >
            Copy Invite Link
          </MenuItem>
          <MenuItem
            onClick={() => {
              setCurrentClientId(clientInvite.id ?? "");
              onConfirmDeleteOpen();
            }}
          >
            Delete Client
          </MenuItem>
        </MenuList>
      </Menu>
      <AlertDialog
        isOpen={isConfirmDeleteOpen}
        leastDestructiveRef={cancelConfirmDeleteRef}
        onClose={onConfirmDeleteClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Client
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this client? This action can't be
              undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelConfirmDeleteRef}
                onClick={onConfirmDeleteClose}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={async () => {
                  await deleteClientInvite(currentClientId ?? "", userId ?? "");
                  setCurrentClientId(null);
                  toast({
                    title: "Client deleted",
                    status: "success",
                  });
                  refreshClientCount();
                  onConfirmDeleteClose();
                  // create a timer that resets the search form
                  setTimeout(() => {
                    setRefreshClients(true);
                  }, 500);
                }}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </HStack>
  );
};

export const ClientSearchResults = connectStateResults(SearchResultTable);
