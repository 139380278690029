import { IAddress } from "../types/IAddress";
import { type BasicDoc } from "react-instantsearch-core";

export const formatGooglePlaceAddress = (address: IAddress) => {
  return `${address?.streetNumber} ${address?.streetShort}, ${address?.city}, ${address?.state} ${address?.postalCode}`;
};

export const formatGooglePlaceAddressFromSearch = (hit: BasicDoc) => {
  return `${hit["address.streetNumber"]} ${hit["address.streetShort"]}, ${hit["address.city"]}, ${hit["address.state"]} ${hit["address.postalCode"]}`;
};
