import {
  useSendPasswordResetEmail,
  useSignInWithEmailAndPassword,
  useSignOut,
} from "react-firebase-hooks/auth";
import { auth } from "../../core/firebase";

import { Link, useNavigate } from "react-router-dom";
// import { FIREBASE_ERRORS } from "../../../config/errors";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  StackProps,
  Text,
  Link as ChakraLink,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Logo } from "../../layout/components/Logo";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { sign } from "crypto";
// import { useSignInWithEmailPassword } from "../../state/CurrentUser";

export const NoAccessForm = (props: StackProps) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm();
  const navigate = useNavigate();
  const [signOut, loading, error] = useSignOut(auth);

  const onSignOut = async (data: any) => {
    await signOut();
    navigate("/auth/sign-in");
  };

  const onRequestAccess = async (data: any) => {
    await signOut();
    navigate("/auth/request-access");
  };

  return (
    <Stack width="full" maxWidth="md" spacing="8" p={8}>
      <Stack spacing="6" align="center">
        {isMobile && <Logo />}
        <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
          <Heading size={{ base: "xs", md: "sm" }}>Access Denied</Heading>
        </Stack>
      </Stack>

      <Stack spacing="4">
        <Text>
          EasyHome Client Connect is only available to registered agents.
        </Text>
        <Button colorScheme="teal" size="lg" onClick={onRequestAccess}>
          Request Agent Access
        </Button>
        <Text textAlign="center">or</Text>
        <Button colorScheme="teal" size="lg" onClick={onSignOut}>
          Sign Out
        </Button>
      </Stack>
    </Stack>
  );
};
