import {
  Box,
  InputGroup,
  Input,
  InputLeftElement,
  Icon,
} from "@chakra-ui/react";
import { connectSearchBox } from "react-instantsearch-dom";
import { FiSearch } from "react-icons/fi";

const SearchBox = (props: any) => {
  const { currentRefinement, isSearchStalled, refine } = props;
  return (
    <Box px={{ base: "4", md: "6" }} pb="4">
      <InputGroup maxW="xs">
        <InputLeftElement pointerEvents="none">
          <Icon as={FiSearch} color="fg.muted" boxSize="5" />
        </InputLeftElement>
        <Input
          placeholder="Search"
          defaultValue={currentRefinement}
          onChange={(event: any) => refine(event.currentTarget.value)}
        />
      </InputGroup>
    </Box>
    // <form noValidate action="" role="search">
    //   <input
    //     type="search"
    //     value={currentRefinement}
    //     onChange={event => refine(event.currentTarget.value)}
    //   />
    //   <button onClick={() => refine('')}>Reset query</button>
    //   {isSearchStalled ? 'My search is stalled' : ''}
    // </form>
  );
};

export const CustomSearchBox = connectSearchBox(SearchBox);
