import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Container,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { ClientSearchResults } from "./components/SearchResultTable";
import { MemberTable } from "./components/MemberTable";
import { CustomSearchBox } from "./components/SearchBox";
import { PageHeader } from "../../components/PageHeader";
import { collection, orderBy, query } from "firebase/firestore";
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import { db } from "../../core/firebase";
import { config } from "../../core/config";
import {
  clientCountState,
  userIdState,
  userProfileState,
} from "../../state/User";
import { useRecoilValue } from "recoil";
import { activeSubscriptionQuery } from "../../state/Stripe";
import { useNavigate } from "react-router-dom";
import {
  InstantSearch,
  SearchBox,
  Configure,
  Pagination,
} from "react-instantsearch-dom";
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import { SearchPagination } from "./components/SearchPagination";

export const Clients = () => {
  const userId = useRecoilValue(userIdState);
  // const isMobile = useBreakpointValue({ base: true, md: false });
  const [clients, loading, error] = useCollectionDataOnce(
    query(
      collection(db, `agents/${userId}/client-invites`),
      orderBy("createdAt", "desc")
    )
  );
  const subscriptions = useRecoilValue(activeSubscriptionQuery);
  const clientCount = useRecoilValue(clientCountState);
  const userProfile = useRecoilValue(userProfileState);
  const navigate = useNavigate();

  const getSearchClient = (searchKey: string) => {
    const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
      server: {
        nodes: [
          {
            host: config.typesense.host,
            port: 443,
            protocol: "https",
          },
        ],
        apiKey: searchKey,
      },
      additionalSearchParameters: {
        preset: "invite_view",
      },
    });
    return typesenseInstantsearchAdapter.searchClient;
  };

  return (
    <Container>
      <PageHeader
        title="My Clients"
        subtitle="Add new clients (or new homes for an existing client) and EasyHome will help them manage their home. Less calls for you, more peace of mind for them. Win-win!"
      />
      <Stack spacing={{ base: "8", lg: "6" }}>
        {clientCount >= 5 && subscriptions.length === 0 && (
          <Alert>
            <AlertIcon />
            <Text>
              <strong>
                You've reached the maximum number of free clients.
              </strong>{" "}
              Choose a plan from the dashboard to add additional clients.
            </Text>
          </Alert>
        )}
        <Box
          bg="bg-surface"
          boxShadow={{ base: "none", md: "sm" }}
          borderRadius={{ base: "none", md: "lg" }}
        >
          <Stack spacing="5">
            <Box px={{ base: "4", md: "6" }} pt="5">
              <Stack
                direction={{ base: "row", md: "row" }}
                justify="space-between"
              >
                <Text fontSize="lg" fontWeight="medium">
                  Viewing All Clients
                </Text>
                <Box>
                  <Button
                    colorScheme="teal"
                    isDisabled={clientCount >= 5 && subscriptions.length === 0}
                    onClick={() => {
                      navigate("/clients/add");
                    }}
                  >
                    Add Client
                  </Button>
                </Box>
              </Stack>
            </Box>
            {typeof userProfile?.searchKey !== "undefined" &&
              userProfile?.searchKey !== "" && (
                <Box px={{ base: "4", md: "6" }}>
                  <InstantSearch
                    indexName="invites"
                    searchClient={getSearchClient(
                      userProfile?.searchKey as string
                    )}
                    refresh={true}
                  >
                    <CustomSearchBox />
                    <Configure hitsPerPage={5} />
                    <ClientSearchResults />

                    <SearchPagination />
                  </InstantSearch>
                </Box>
              )}
          </Stack>
        </Box>
      </Stack>
    </Container>
  );
};
