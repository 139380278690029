import {
  Button,
  Container,
  Stack,
  Text,
  Flex,
  Table,
  Image,
  Spacer,
  Box,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { useRecoilValue } from "recoil";
import { Link as ReactRouterLink } from "react-router-dom";
import { useState, useEffect } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore";
import { DateTime } from "luxon";
import { userIdState } from "../../state/User";
import { PageHeader } from "../../components/PageHeader";
import { ImageBanner } from "../../components/ImageBanner";
import { db } from "../../core/firebase";
import { IVoucher } from "../../types/IVoucher";

export const ClientGifts = () => {
  const userId = useRecoilValue(userIdState);
  const [gifts, setGifts] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchGifts = async () => {
      const q = query(
        collection(db, "vouchers"),
        where("agentId", "==", userId)
      );
      const querySnapshot = await getDocs(q);
      const gifts = [];
      for (let i = 0; i < querySnapshot.docs.length; i++) {
        // get the doc data
        const gift = querySnapshot.docs[i].data();
        const clientDoc = await getDoc(
          doc(db, `agents/${userId}/client-invites/${gift.clientInviteId}`)
        );
        const clientData = clientDoc.data();
        const returnGift = Object.assign(gift, {
          clientName: `${clientData?.firstName} ${clientData?.lastName}`,
        });
        gifts.push(returnGift);
      }
      setGifts(gifts);
      setIsLoading(false);
    };

    fetchGifts();
  }, [userId]);

  const getReceiptUrl = async (gift: IVoucher) => {
    try {
      // get the paymentId from the voucher
      const q = query(
        collection(db, "transactions"),
        where("userId", "==", userId),
        where("voucherId", "==", gift.id),
        where("type", "==", "voucherPurchase")
      );
      const querySnapshot = await getDocs(q);
      const stripeObjects = querySnapshot.docs[0].data().stripeObjects;
      const paymentId = stripeObjects[0].stripePaymentId;
      const paymentDoc = await doc(db, `users/${userId}/payments/${paymentId}`);
      const paymentSnap = await getDoc(paymentDoc);
      if (paymentSnap.exists()) {
        const payment = paymentSnap.data();
        const url = payment.charges.data[0].receipt_url;
        return url;
      } else {
        throw new Error("Payment not found");
      }
    } catch (error) {
      alert(
        "Receipt not found. Contact us at info@easyhomeinc.com for assistance."
      );
    }
  };

  return (
    <Container>
      <ImageBanner
        title="Stay Top of Mind With EasyHome Gift Vouchers"
        subtitle="Help your clients protect their investments with discounts off future home services booked through the EasyHome concierge."
        image="/assets/client-connect-header-bg.jpg"
      />
      {isLoading ? (
        <Flex height="100vh" bg="bg-canvas" justify="center" align="center">
          <Spinner size="xl" />
        </Flex>
      ) : (
        <Stack>
          {gifts.length > 0 ? (
            <>
              <PageHeader
                title="My Sent Gift Vouchers"
                subtitle="Track your client gifts below."
              />
              <Table>
                <Thead>
                  <Tr>
                    <Th>To: </Th>
                    <Th>Date Purchased</Th>
                    <Th>Amount</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {gifts.map((gift) => (
                    <Tr key={gift.id}>
                      <Td>
                        <Text color="muted">{gift.clientName}</Text>
                      </Td>
                      <Td>
                        <Text color="muted">
                          {DateTime.fromMillis(gift.createdAt).toISODate()}
                        </Text>
                      </Td>
                      <Td>
                        <Text color="muted">
                          ${(gift.amount / 100).toFixed(2)}
                        </Text>
                      </Td>
                      <Td>
                        <Button
                          size="sm"
                          variant="outline"
                          onClick={async () => {
                            const url = await getReceiptUrl(gift);
                            window.open(url, "_blank", "noreferrer");
                          }}
                        >
                          View Receipt
                        </Button>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </>
          ) : (
            <Stack
              maxW="container.sm"
              alignSelf="center"
              align="center"
              bg="white"
              p={12}
              mt={8}
              borderRadius="lg"
            >
              <Text fontSize="lg" fontWeight="bold">
                Ready to send your first client gift?
              </Text>
              <Text pt={3} align="center">
                From the "Clients" page, look for the "Send Gift" button next to
                the client you want to send a gift to.
              </Text>
              <Alert>
                <Text>
                  Clients must accept your invite before you can send them a
                  gift.
                </Text>
              </Alert>
              <Image
                src="/assets/gift-instruction-image.jpg"
                width="300px"
                height="auto"
                fallback={<Box bg="gray.200" width="80px" height="80px"></Box>}
                alt=""
              />
              <Spacer h={6} />
              <Button
                colorScheme="teal"
                w="200px"
                as={ReactRouterLink}
                to="/clients"
                mb={6}
              >
                View Clients
              </Button>
            </Stack>
          )}
        </Stack>
      )}
    </Container>
  );
};
