import { User } from "firebase/auth";
import {
  collection,
  doc,
  DocumentData,
  getCountFromServer,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { atom, selector } from "recoil";
import { auth, db } from "../core/firebase";

export const userIdState = atom<string | null>({
  key: "UserId",
  effects: [
    (ctx) => {
      if (ctx.trigger === "get") {
        return auth.onAuthStateChanged((user: User | null) => {
          if (user) {
            ctx.setSelf(user.uid);
          } else {
            ctx.setSelf(null);
          }
        });
      }
    },
  ],
});

export const userProfileState = selector<DocumentData>({
  key: "UserProfile",
  get: async ({ get }) => {
    const userId = get(userIdState);
    if (userId) {
      const docRef = await getDoc(doc(db, "users", userId));
      return docRef.data();
    } else {
      return null;
    }
  },
});

export const agentProfileState = selector<DocumentData>({
  key: "AgentProfile",
  get: async ({ get }) => {
    const userId = get(userIdState);
    if (userId) {
      const docRef = await getDoc(
        doc(db, "agents", userId, "shared", "profile")
      );
      return docRef.data();
    } else {
      return null;
    }
  },
});

export const clientCountState = selector<number>({
  key: "ClientCount",
  get: async ({ get }) => {
    const userId = get(userIdState);
    if (userId) {
      const coll = collection(db, `agents/${userId}/client-invites`);
      const snapshot = await getCountFromServer(coll);
      return snapshot.data().count;
    } else {
      return 0;
    }
  },
});

export const onboardedClientCountState = selector<number>({
  key: "OnboardedClientCount",
  get: async ({ get }) => {
    const userId = get(userIdState);
    if (userId) {
      const coll = query(
        collection(db, `agents/${userId}/client-invites`),
        where("isClaimed", "==", true)
      );
      const snapshot = await getCountFromServer(coll);
      return snapshot.data().count;
    } else {
      return 0;
    }
  },
});

export const agentAlertsState = selector<DocumentData[]>({
  key: "AgentAlerts",
  get: async ({ get }) => {
    const userId = get(userIdState);
    if (userId) {
      const alerts = query(
        collection(db, "userActions"),
        where("userId", "==", userId),
        where("isDismissed", "==", false)
      );
      const querySnapshot = await getDocs(alerts);
      const data = querySnapshot.docs.map((doc: any) => doc.data());
      return data;
    }
    return [];
  },
});
