import { AuthPageLayout } from "../../layout/AuthPageLayout";
import { ResetPasswordForm } from "./ResetPasswordForm";

const ResetPassword = () => (
  <AuthPageLayout>
    <ResetPasswordForm
      px={{ base: "4", md: "8" }}
      py={{ base: "12", md: "48" }}
      width="full"
      maxW="md"
    />
  </AuthPageLayout>
);

export default ResetPassword;
