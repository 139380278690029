import { Box, Container } from "@chakra-ui/react";
import { NavbarWithoutDrawer } from "./components/NavbarWithoutDrawer";

type LegalPageLayoutProps = {
  children: any;
};

export const LegalPageLayout: React.FC<LegalPageLayoutProps> = ({
  children,
}) => (
  <Box as="section" height="100vh" overflowY="auto">
    <NavbarWithoutDrawer />
    <Container pt={{ base: "8", lg: "12" }} pb={{ base: "12", lg: "24" }}>
      {children}
    </Container>
  </Box>
);
