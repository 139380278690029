import {
  Box,
  Circle,
  Flex,
  Stack,
  Link,
  useColorModeValue as mode,
} from "@chakra-ui/react";

import { NavLink, useNavigate } from "react-router-dom";
import { AccountSwitcher } from "./AccountSwitcher";
import { NavItem } from "./NavItem";
import { BiBuoy, BiCog, BiHome, BiLogOut, BiUserCircle } from "react-icons/bi";
import { Logo } from "./Logo";
import { useSignOut } from "react-firebase-hooks/auth";
import { auth } from "../../core/firebase";
import {
  AddressBookIcon,
  GiftIcon,
  UserSquareIcon,
} from "../../components/icons/Icons";
import { useRecoilValue } from "recoil";
import { agentProfileState } from "../../state/User";

export const SideBar = () => {
  const [signOut] = useSignOut(auth);
  const navigate = useNavigate();
  const agentProfile = useRecoilValue(agentProfileState);

  const hasProfile =
    typeof agentProfile?.fullName !== "undefined" &&
    agentProfile?.fullName !== null;

  return (
    <Flex as="section" minH="var(--chakra-vh)" bg="bg-canvas">
      <Flex
        flex="1"
        bg="gray.800"
        color="white"
        overflowY="auto"
        boxShadow="md"
        maxW={{ base: "full", sm: "xs" }}
        py={{ base: "6", sm: "8" }}
        px={{ base: "4", sm: "6" }}
      >
        {/*
    <Box
      height="100vh"
      position="relative"
      w="64"
      bg="gray.800"
      color="white"
      fontSize="sm"
    >
      <Flex h="full" direction="column" px="4" py="4"> */}
        <Stack justify="space-between" spacing="1">
          <Box pt="0" ps="2">
            <Logo />
          </Box>
          {/* <AccountSwitcher /> */}
          <Stack spacing="8" flex="1" pt="8">
            <Stack spacing="1">
              <NavLink to="/dashboard">
                {({ isActive }) => (
                  <NavItem
                    active={isActive}
                    icon={<BiHome />}
                    label="Dashboard"
                  />
                )}
              </NavLink>

              <NavLink to="/profile">
                {({ isActive }) => (
                  <NavItem
                    active={isActive}
                    icon={<UserSquareIcon />}
                    label="Profile"
                  />
                )}
              </NavLink>

              {hasProfile && (
                <NavLink to="/clients">
                  {({ isActive }) => (
                    <NavItem
                      active={isActive}
                      icon={<AddressBookIcon />}
                      label="Clients"
                    />
                  )}
                </NavLink>
              )}

              {hasProfile && (
                <NavLink to="/client-gifts">
                  {({ isActive }) => (
                    <NavItem
                      active={isActive}
                      icon={<GiftIcon />}
                      label="Client Gifts"
                    />
                  )}
                </NavLink>
              )}
            </Stack>
          </Stack>
          <Box>
            <Stack spacing="1">
              <NavLink to="/settings">
                {({ isActive }) => (
                  <NavItem
                    subtle
                    active={isActive}
                    icon={<BiCog />}
                    label="Settings"
                  />
                )}
              </NavLink>

              <NavLink to="mailto:info@easyhomeinc.com">
                <NavItem subtle icon={<BiBuoy />} label="Support" />
              </NavLink>

              <NavItem
                subtle
                icon={<BiLogOut />}
                label="Sign Out"
                onClick={async () => {
                  await signOut();
                  navigate("/auth/sign-in");
                }}
              />
            </Stack>
          </Box>
        </Stack>
      </Flex>
    </Flex>
  );
};
