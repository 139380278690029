import { connectPagination } from "react-instantsearch-dom";
import {
  Pagination as ArkPagination,
  PaginationProps as ArkPaginationProps,
  PaginationEllipsis,
  PaginationNextPageTrigger,
  PaginationPageTrigger,
  PaginationPrevPageTrigger,
} from "@ark-ui/react";
import {
  Button,
  Center,
  IconButton,
  List,
  ListItem,
  Text,
  Box,
  Container,
  VisuallyHidden,
  useBreakpointValue,
} from "@chakra-ui/react";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";

export type PaginationProps = Omit<ArkPaginationProps, "children">;

export const Pagination = (props: PaginationProps) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <ArkPagination {...props}>
      {({ pages, page }) => (
        <List display="flex" justifyContent="space-between">
          <ListItem>
            <PaginationPrevPageTrigger asChild>
              {isMobile ? (
                <IconButton
                  variant="secondary"
                  icon={<FiArrowLeft />}
                  aria-label="Previous Page"
                />
              ) : (
                <Button variant="tertiary" leftIcon={<FiArrowLeft />}>
                  Previous <VisuallyHidden>Page</VisuallyHidden>
                </Button>
              )}
            </PaginationPrevPageTrigger>
          </ListItem>

          <List display={{ base: "none", md: "flex" }} gap="1">
            {pages.map((page, index) =>
              page.type === "page" ? (
                <ListItem key={index}>
                  <PaginationPageTrigger asChild {...page}>
                    <Button variant="tertiary">{page.value}</Button>
                  </PaginationPageTrigger>
                </ListItem>
              ) : (
                <ListItem key={index} alignItems="center" display="flex">
                  <PaginationEllipsis index={index}>
                    <Text as="span" color="fg.emphasized">
                      &#8230;
                    </Text>
                  </PaginationEllipsis>
                </ListItem>
              )
            )}
          </List>
          <ListItem as={Center} display={{ md: "none" }}>
            {pages.length > 0 && (
              <Text fontWeight="medium" color="fg.emphasized">
                Page {page} of {pages.length}
              </Text>
            )}
          </ListItem>

          <ListItem>
            <PaginationNextPageTrigger asChild>
              {isMobile ? (
                <IconButton
                  variant="secondary"
                  icon={<FiArrowRight />}
                  aria-label="Next Page"
                />
              ) : (
                <Button variant="tertiary" rightIcon={<FiArrowRight />}>
                  Next <VisuallyHidden>Page</VisuallyHidden>
                </Button>
              )}
            </PaginationNextPageTrigger>
          </ListItem>
        </List>
      )}
    </ArkPagination>
  );
};

const CustomPagination = (props: any) => {
  const { currentRefinement, nbPages, refine } = props;
  return (
    <Box bg="bg.surface">
      <Container py={{ base: "12", md: "16" }}>
        <Pagination
          count={nbPages}
          pageSize={1}
          siblingCount={2}
          page={currentRefinement}
          onChange={(e) => refine(e.page)}
        />
      </Container>
    </Box>
  );
};

export const SearchPagination = connectPagination(CustomPagination);
